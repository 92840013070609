import { SearchOutlined } from '@ant-design/icons';
import {
  AntdLayout,
  Button,
  Dropdown,
  Icons,
  Menu,
  Space,
} from '@pankod/refine-antd';
import {
  useGetLocale,
  useLogout,
  useNavigation,
  useQuery,
  // useSetLocale,
} from '@pankod/refine-core';
import { useKBar } from '@pankod/refine-kbar';
import type { MenuProps } from 'antd';
import { Divider, Input, theme } from 'antd';
import { adminAuthenticationApi } from 'api/coreBackend/defHttp';
import SetupGuide from 'containers/SetupGuide';
import ViewStoreButton from 'containers/ViewStoreButton';
import { cloneElement } from 'react';

const { DownOutlined } = Icons;

const { useToken } = theme;

export const Header: React.FC = () => {
  const { query } = useKBar();
  const locale = useGetLocale();
  const { mutate: mutateLogout } = useLogout();
  const currentLocale = locale();
  const { push } = useNavigation();

  const { data: user } = useQuery(['adminAuthenticationApi.adminGetMe'], () => {
    return adminAuthenticationApi.adminGetMe();
  });

  const { token } = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: 'none',
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Personal Info',
      icon: <Icons.UserOutlined />,
      onClick: () => {
        push('/personal');
      },
    },
    {
      key: '2',
      label: 'Logout',
      icon: <Icons.LogoutOutlined />,
      onClick: () => {
        mutateLogout();
      },
    },
  ];

  const isAdmin = user?.data.roleId === 1;

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      <Menu.Item>English</Menu.Item>
      {/* {[...(i18n.languages || [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
          }
        >
          {lang === 'en' ? 'English' : 'German'}
        </Menu.Item>
      ))} */}
    </Menu>
  );

  return (
    <AntdLayout.Header
      className="drop-shadow"
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px 24px',
        height: '56px',
        backgroundColor: '#FFF',
        zIndex: 100,
      }}
    >
      <div className="mr-auto flex items-center gap-1">
        <img className="h-9" src="/images/common/logo.png" alt="logo" />
        <h1 className="mb-0 italic font-bold text-xl">Talfa</h1>
      </div>
      <div className="flex-1 mx-4 flex justify-center items-center">
        <Input
          className="max-w-lg"
          // size="large"
          placeholder="Search (command / ctrl + k)"
          prefix={<SearchOutlined />}
          onClick={() => {
            query.toggle();
          }}
        />
      </div>
      <ViewStoreButton className="mr-3" />
      {isAdmin && (
        <Space style={{ marginRight: '8px' }}>
          <SetupGuide />
        </Space>
      )}

      <Dropdown overlay={menu}>
        <Button type="link">
          <Space>
            {/* <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} /> */}
            {currentLocale === 'en' ? 'English' : 'German'}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <Dropdown
        menu={{ items }}
        dropdownRender={(_menu) => (
          <div style={contentStyle}>
            <div className="flex items-center gap-4 px-4 py-3">
              <div className="w-10 h-10 rounded-full flex items-center justify-center bg-slate-200">
                {user?.data.name?.[0]}
              </div>
              <div>
                <p className="mb-0">
                  {user?.data.name} ({user?.data.role.name})
                </p>
                <p className="mb-0 text-xs">{user?.data.email}</p>
              </div>
            </div>
            <Divider style={{ margin: 0 }} />
            {cloneElement(_menu as React.ReactElement, { style: menuStyle })}
          </div>
        )}
      >
        <button className="w-8 border-none cursor-pointer h-8 rounded-full flex items-center justify-center bg-slate-200 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          {user?.data.name?.[0]}
        </button>
      </Dropdown>
      {/* {isAdmin && <ConnectWallet />} */}
    </AntdLayout.Header>
  );
};
