import { ClockCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@pankod/refine-core';
import { Button, Divider, Modal } from 'antd';
import { projectManagementAdminApi } from 'api/coreBackend/defHttp';
import dayjs from 'dayjs';
import { useState } from 'react';

interface Props {
  projectId: number;
  className?: string;
}

export default function ApprovalTimelineButton({
  projectId,
  className,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data } = useQuery(
    [
      'projectManagementAdminApi.getManyBaseAdminProjectApplicationControllerProjectApplication',
    ],
    () => {
      return projectManagementAdminApi.getManyBaseAdminProjectApplicationControllerProjectApplication();
    }
  );

  // console.log('ProjectsApplication', data);

  const filteredData = (data?.data as any[])?.filter(
    (_data) => _data.projectId === projectId
  );

  return (
    <>
      <Button
        className={className}
        icon={<ClockCircleOutlined />}
        onClick={() => {
          setIsModalOpen(true);
        }}
        // loading={updateProjectMutation.isLoading}
      >
        Approval Timeline
      </Button>
      <Modal
        title="Approval Timeline"
        open={isModalOpen}
        okText="Close"
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => {
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="mt-4 max-h-[60vh] overflow-y-auto">
          {filteredData?.map((_data: any) => {
            return (
              <div className="mt-4">
                <p className="mb-2">Status: {_data.status}</p>
                {_data.submittedAt && (
                  <>
                    <p className="mb-2">
                      Submitted date: {dayjs(_data.submittedAt).format('LLL')}
                    </p>
                    <p className="mb-2">
                      Sponsor's comment:{' '}
                      {_data.submitMessage ? _data.submitMessage : '--'}
                    </p>
                  </>
                )}
                {_data.reviewedAt && (
                  <>
                    <p className="mb-2">
                      Reviewed date: {dayjs(_data.reviewedAt).format('LLL')}
                    </p>
                    <p className="mb-2">
                      Admin's comment:{' '}
                      {_data.reviewMessage ? _data.reviewMessage : '--'}
                    </p>
                  </>
                )}
                <Divider />
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
}
