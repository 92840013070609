import { Button, Form, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { useProjectMethod } from 'views/projects/hooks/useProjectMethod';

interface Props {
  projectApplicationId: number;
  projectId: number;
  disabled?: boolean;
}

export default function RejectButton({
  projectId,
  projectApplicationId,
  disabled = false,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { rejectProjectMutation } = useProjectMethod();

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => {
          setIsModalOpen(true);
        }}
        loading={rejectProjectMutation.isLoading}
      >
        Reject
      </Button>
      <Modal
        title="Reject Submission"
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
          rejectProjectMutation.mutate({
            projectId,
            projectApplicationId,
            message: form.getFieldValue('message'),
          });
        }}
        okText="Confirm"
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Please provide details on why this project was declined and how the sponsors could modify it."
            name="message"
            required
          >
            <TextArea
              showCount
              maxLength={100}
              style={{ height: 120, marginBottom: 24 }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
