import {
  CreateButton,
  DateField,
  EditButton,
  getDefaultSortOrder,
  List,
  ShowButton,
  Space,
  Table,
  Tag,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import type { Project } from '@tokenbricks/talfa-backend-typescript-axios-client';
import { ProjectAoStatusEnum } from '@tokenbricks/talfa-backend-typescript-axios-client';
import classNames from 'classnames';
import ProjectCMSDataLoader from 'containers/ProjectCMSDataLoader';
import { ethers } from 'ethers';

import { numberFormat } from '../../utils/numberFormat';

export const ProjectList: React.FC = () => {
  const { tableProps, sorter } = useTable({
    dataProviderName: 'crud',
    resource: 'projects',
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
  });

  return (
    <List createButtonProps={CreateButton}>
      <Table {...tableProps} rowKey="id">
        <Table.Column<Project>
          dataIndex="name"
          title="Name"
          render={(_, record) => {
            return (
              <ProjectCMSDataLoader projectId={record.id}>
                {(data) => {
                  return <div>{data?.name}</div>;
                }}
              </ProjectCMSDataLoader>
            );
          }}
        />
        <Table.Column
          dataIndex="price"
          title="Price Per Token"
          render={(value) => <div>${numberFormat(value)}</div>}
        />
        <Table.Column<Project>
          dataIndex={['securityToken', 'tokenSale', 'totalSoldQuantity']}
          title="Total Sold Quantity"
          render={(value, record) => (
            <TextField
              value={numberFormat(
                Number(
                  ethers.utils.formatUnits(
                    value,
                    record?.securityToken?.decimals
                  )
                )
              )}
            />
          )}
        />
        <Table.Column<Project>
          dataIndex={['securityToken', 'tokenSale', 'totalSaleSupply']}
          title="Total Sale Supply"
          render={(value, record) => (
            <TextField
              value={numberFormat(
                Number(
                  ethers.utils.formatUnits(
                    value,
                    record?.securityToken?.decimals
                  )
                )
              )}
            />
          )}
        />
        <Table.Column
          dataIndex={['securityToken', 'tokenSale', 'saleStartedAt']}
          title="Sale Started At"
          render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column
          dataIndex={['securityToken', 'tokenSale', 'saleEndedAt']}
          title="Sale Ended At"
          render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column
          dataIndex={['updatedAt']}
          title="Last Edited"
          sorter
          defaultSortOrder={getDefaultSortOrder('updatedAt', sorter)}
          render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column
          dataIndex={'status'}
          title="Project Status"
          render={(value: string) => {
            let color = '';
            switch (value) {
              case ProjectAoStatusEnum.PUBLISHED:
                color = 'green';
                break;
              case ProjectAoStatusEnum.UNPUBLISHED:
                color = 'orange';
                break;
              case ProjectAoStatusEnum.DRAFT:
                color = 'blue';
                break;
              default:
                color = 'green';
                break;
            }
            return (
              <Tag className="capitalize" color={color}>
                {value.toLocaleLowerCase()}
              </Tag>
            );
          }}
        />
        <Table.Column<Project>
          dataIndex={['latestProjectApplication', 'status']}
          title="Approval Status"
          render={(value) => {
            let color;

            switch (value) {
              case 'APPROVED':
                color = 'gold';
                break;
              case 'REJECTED':
                color = 'red';
                break;
              case 'SUBMITTED':
                color = 'purple';
                break;
              default:
                color = 'blue';
                break;
            }

            return (
              <Tag
                className={classNames('capitalize', { hidden: !value })}
                color={color}
              >
                {value?.toLocaleLowerCase()}
              </Tag>
            );
          }}
          // render={(value, record) => (
          //   <ProjectApplicationDataLoader
          //     id={record.latestProjectApplicationId}
          //   >
          //     {(data) => {
          //       let statue = '';
          //       const projectApplicationStatus = data?.status;
          //       const projectStatus = record.status;
          //       return (
          //         <Tag
          //           color={
          //             value === ProjectAoStatusEnum.PUBLISHED ? 'green' : 'blue'
          //           }
          //         >
          //           {value ?? data?.status ?? record.status}
          //         </Tag>
          //       );
          //     }}
          //   </ProjectApplicationDataLoader>
          // )}
        />

        <Table.Column<Project>
          title="Actions"
          dataIndex="id"
          render={(value) => (
            <Space>
              <EditButton hideText size="small" recordItemId={value} />
              <ShowButton hideText size="small" recordItemId={value} />
              {/* <Link to={`/projects/setting/${value}`}>
                <Button size="small" icon={<SettingOutlined />} />
              </Link> */}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
