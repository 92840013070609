import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber } from 'antd';
import type { FormInstance } from 'antd/es/form';
import classNames from 'classnames';
import CustomFieldLabel from 'views/projects/containers/CustomFieldLabel';

interface Props {
  form: FormInstance<any>;
  isShow: boolean;
  isDraft?: boolean;
  diff: any;
}

export default function SaleDetailsFormItems({
  form,
  isShow,
  isDraft,
  diff,
}: Props) {
  return (
    <div
      className={classNames('hidden', {
        '!block': isShow,
      })}
    >
      <Form.Item
        name="totalFundraising"
        // label="Total Fundraising"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.totalFundraising !== undefined,
            })}
          >
            Total Fundraising
          </div>
        }
        rules={[{ required: isShow }]}
      >
        <InputNumber
          className="w-full"
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>
      <Form.Item
        name="pricePerToken"
        // label="Price Per Token"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.pricePerToken !== undefined,
            })}
          >
            Price Per Token
          </div>
        }
        rules={[{ required: isShow }]}
      >
        <InputNumber
          className="w-full"
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>
      <Form.Item
        name="totalTokens"
        // label="Total Tokens"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.totalTokens !== undefined,
            })}
          >
            Total Tokens
          </div>
        }
        rules={[
          { required: isShow },
          {
            message:
              'Please revise either the total fundraising amount or the price per token to ensure that the total number of tokens is represented as a whole number.',
            validator: (_, value: string) => {
              if (Number.isInteger(value)) {
                return Promise.resolve();
              }
              throw new Error('Invalid value');
            },
          },
        ]}
      >
        <InputNumber
          disabled
          className="w-full"
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value!.replace(',', '')}
        />
      </Form.Item>
      <Form.Item
        name="minimumInvestment"
        // label="Minimum Investment"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.minimumInvestment !== undefined,
            })}
          >
            Minimum Investment
          </div>
        }
        rules={[{ required: isShow }]}
      >
        <InputNumber
          className="w-full"
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>
      <Form.Item
        name="minimumHoldingPeriod"
        // label="Minimum Holding Period (days)"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.minimumHoldingPeriod !== undefined,
            })}
          >
            Minimum Holding Period (days)
          </div>
        }
        rules={[{ required: isShow }]}
      >
        <InputNumber className="w-full" disabled={!isDraft} />
      </Form.Item>
      <Form.Item
        name="sponsorInvestmentPeriod"
        // label="Sponsor Investment Period"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.sponsorInvestmentPeriod !== undefined,
            })}
          >
            Sponsor Investment Period
          </div>
        }
        rules={[{ required: isShow }]}
      >
        <Input placeholder="10 years" />
      </Form.Item>
      <Form.Item
        name="sponsorCoInvest$"
        // label="Sponsor Co-Invest ($)"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.sponsorCoInvest$ !== undefined,
            })}
          >
            Sponsor Co-Invest ($)
          </div>
        }
      >
        <InputNumber
          className="w-full"
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
        />
      </Form.Item>
      <Form.Item
        name="sponsorCoInvestPercentage"
        // label="Sponsor Co-Invest (%)"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.sponsorCoInvestPercentage !== undefined,
            })}
          >
            Sponsor Co-Invest (%)
          </div>
        }
      >
        <InputNumber
          className="w-full"
          formatter={(value) => `${value}%`}
          parser={(value) => value!.replace('%', '')}
        />
      </Form.Item>
      <Form.List name="saleDetailsCustomFields">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const label = form.getFieldValue(['customFields', name, 'label']);
              return (
                <div key={key} className="mb-2">
                  <Form.Item
                    className="hidden"
                    {...restField}
                    name={[name, 'label']}
                    initialValue="Custom Field"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'field']}
                    label={
                      <>
                        <CustomFieldLabel
                          value={label}
                          formName={name}
                          onSave={(_label) => {
                            form.setFieldValue(
                              ['customFields', name, 'label'],
                              _label
                            );
                          }}
                          remove={remove}
                        />
                      </>
                    }
                    rules={[{ required: true, message: 'Missing first name' }]}
                  >
                    <Input className="w-full" placeholder="value" />
                  </Form.Item>
                </div>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
}
