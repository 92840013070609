import {
  AdminApplicationManagementAdminApi,
  AdminAuthenticationApi,
  AdminGroupManagementAdminApi,
  Configuration,
  ContentManagementSystemAdminApi,
  DocumentManagementAdminApi,
  PaymentProviderSettingAdminApi,
  ProjectManagementAdminApi,
  SupportedChainManagementAdminApi,
} from '@tokenbricks/talfa-backend-typescript-axios-client';
import { createCoreAxios } from 'utils/http/createCoreAxios';

export const coreBackendDefHttp = createCoreAxios();

export const adminAuthenticationApi = new AdminAuthenticationApi(
  new Configuration(),
  process.env.REACT_APP_BASE_API_URL,
  coreBackendDefHttp
);

export const projectManagementAdminApi = new ProjectManagementAdminApi(
  new Configuration(),
  process.env.REACT_APP_BASE_API_URL,
  coreBackendDefHttp
);

export const contentManagementSystemAdminApi =
  new ContentManagementSystemAdminApi(
    new Configuration(),
    process.env.REACT_APP_BASE_API_URL,
    coreBackendDefHttp
  );

export const supportedChainManagementAdminApi =
  new SupportedChainManagementAdminApi(
    new Configuration(),
    process.env.REACT_APP_BASE_API_URL,
    coreBackendDefHttp
  );

export const documentManagementAdminApi = new DocumentManagementAdminApi(
  new Configuration(),
  process.env.REACT_APP_BASE_API_URL,
  coreBackendDefHttp
);

export const paymentProviderSettingAdminApi =
  new PaymentProviderSettingAdminApi(
    new Configuration(),
    process.env.REACT_APP_BASE_API_URL,
    coreBackendDefHttp
  );

export const adminApplicationManagementAdminApi =
  new AdminApplicationManagementAdminApi(
    new Configuration(),
    process.env.REACT_APP_BASE_API_URL,
    coreBackendDefHttp
  );

export const adminGroupManagementAdminApi = new AdminGroupManagementAdminApi(
  new Configuration(),
  process.env.REACT_APP_BASE_API_URL,
  coreBackendDefHttp
);
