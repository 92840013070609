import { InfoCircleOutlined } from '@ant-design/icons';
import type { FormInstance } from 'antd';
import { Button, Form, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { useProjectMethod } from 'views/projects/hooks/useProjectMethod';

interface Props {
  className?: string;
  projectId: number;
  projectApplicationId?: number;
  disabled?: boolean;
  isFirstSubmit?: boolean;
  projectForm: FormInstance<any>;
}

export default function SubmitForApprovalButton({
  className,
  projectId,
  projectApplicationId,
  disabled = false,
  isFirstSubmit = false,
  projectForm,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFirstSubmitModalOpen, setIsFirstSubmitModalOpen] = useState(false);
  const { submitDraftProjectMutation, submitPublishedProjectMutation } =
    useProjectMethod();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    if (isFirstSubmit) {
      setIsFirstSubmitModalOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <Modal
        title={
          <div className="flex gap-4 items-center">
            <InfoCircleOutlined
              style={{ fontSize: '22px', color: '#D90C34' }}
              color="#D90C34"
            />
            <div>Submit for Approval</div>
          </div>
        }
        open={isFirstSubmitModalOpen}
        onOk={() => {
          submitDraftProjectMutation.mutate(
            {
              createData: {
                projectId,
                data: projectForm.getFieldsValue(),
              },
              submitData: { submitMessage: '' },
            },
            {
              onSuccess: () => {
                setIsFirstSubmitModalOpen(false);
              },
            }
          );

          return true;
        }}
        okText="Submit"
        okButtonProps={{ loading: submitDraftProjectMutation.isLoading }}
        onCancel={() => {
          setIsFirstSubmitModalOpen(false);
        }}
      >
        <div className="pl-10">
          <p>
            Any changes after submission will require going through the approval
            process again.
          </p>
          <p>
            Please note that certain fields, such as Min Holding Period and
            Token Name cannot be altered once submitted.
          </p>
        </div>
      </Modal>
      <Modal
        title="Submit for Approval"
        open={isModalOpen}
        onOk={() => {
          if (projectApplicationId)
            submitPublishedProjectMutation.mutate(
              {
                projectApplicationId,
                message: form.getFieldValue('message'),
              },
              {
                onSuccess: () => {
                  setIsModalOpen(false);
                },
              }
            );
        }}
        okText="Submit"
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Describe the changes you made:"
            name="message"
            required
          >
            <TextArea
              // showCount
              // maxLength={1000}
              style={{ height: 120, marginBottom: 24 }}
              placeholder="Describe the changes you made..."
            />
          </Form.Item>
        </Form>
      </Modal>
      <Button className={className} onClick={handleSubmit} disabled={disabled}>
        Submit for Approval
      </Button>
    </>
  );
}
