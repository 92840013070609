import { Col, Row, Show, Space, Table, Typography } from '@pankod/refine-antd';
import type { IResourceComponentsProps } from '@pankod/refine-core';
import { useShow } from '@pankod/refine-core';
import type { AdminGroup } from '@tokenbricks/talfa-backend-typescript-axios-client';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

const { Text } = Typography;

interface DataType {
  key: string;
  name: string;
  email: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
];

export const GroupShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<AdminGroup>();

  return (
    <Show isLoading={queryResult.isLoading} title="Order Detail">
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Name: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult?.data?.data?.name}</Text>
          </Space>
        </Col>
      </Row>

      {/* <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Admin Group Mappings: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult?.data?.data.adminGroupMappings}</Text>
          </Space>
        </Col>
      </Row> */}
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Created At: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>
              {dayjs(queryResult?.data?.data.createdAt).format('LLL')}
            </Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Updated At: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>
              {dayjs(queryResult?.data?.data.updatedAt).format('LLL')}
            </Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Users: </Text>
          </Space>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <Table
            className="ml-2"
            pagination={false}
            columns={columns}
            dataSource={queryResult?.data?.data?.adminGroupMappings?.map(
              (_data) => ({
                key: String(_data.id) ?? '0',
                name: _data.admin?.name ?? '',
                email: _data.admin?.email ?? '',
              })
            )}
          />
        </Col>
      </Row>
    </Show>
  );
};
