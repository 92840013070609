import MDEditor from '@uiw/react-md-editor';
import { Form, Input } from 'antd';
import type { FormInstance } from 'antd/es/form';
import classNames from 'classnames';
import CollapseMenuDnd from 'views/content/containers/CollapseMenuDnd';

interface Props {
  form: FormInstance<any>;
  isShow: boolean;
  isDraft?: boolean;
  diff: any;
}

export default function FullDescriptionsFormItems({
  form,
  isShow,
  diff,
}: Props) {
  return (
    <div
      className={classNames('hidden', {
        '!block': isShow,
      })}
    >
      <Form.Item
        // label="Descriptions"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.contents !== undefined,
            })}
          >
            Descriptions
          </div>
        }
      >
        <Form.List name="contents">
          {(fields, { add, remove, move }) => {
            const items = fields.map(({ key, name, ...restField }, index) => {
              const title = form.getFieldValue(['contents', name, 'title']);

              return {
                id: key,
                index,
                name: title,
                content: (
                  <>
                    <Form.Item
                      {...restField}
                      name={[name, 'title']}
                      label="Title"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'content']}
                      label="Description"
                    >
                      <MDEditor data-color-mode="light" />
                    </Form.Item>
                  </>
                ),
              };
            });

            return (
              <>
                <CollapseMenuDnd items={items} remove={remove} move={move} />
                <div
                  className="w-full h-12 text-indigo-500 font-semibold cursor-pointer flex items-center justify-center hover:bg-slate-100 transition"
                  onClick={() => {
                    add({}, fields.length);
                  }}
                >
                  + Add an entry
                </div>
              </>
            );
          }}
        </Form.List>
      </Form.Item>
    </div>
  );
}
