import './i18n';
import './styles/main.scss';
import '@pankod/refine-antd/dist/reset.css';

import { QueryClient, QueryClientProvider } from '@pankod/refine-core';
import { Buffer } from 'buffer';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

// polyfill Buffer for client
// @ts-ignore
if (!window.Buffer) {
  // @ts-ignore
  window.Buffer = Buffer;
}

export const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    {/* <React.Suspense fallback={<FullScreenLoading />}> */}
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
    {/* </React.Suspense> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
