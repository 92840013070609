import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DateField,
  DatePicker,
  Form,
  getDefaultSortOrder,
  Input,
  List,
  NumberField,
  Row,
  Select,
  ShowButton,
  Space,
  Table,
  TextField,
  useTable,
} from '@pankod/refine-antd';
import type {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from '@pankod/refine-core';
import type { Order } from '@tokenbricks/talfa-backend-typescript-axios-client';
import type { Dayjs } from 'dayjs';

const { RangePicker } = DatePicker;

export const OrderList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter, searchFormProps } = useTable<
    Order,
    HttpError,
    {
      orderCode: string;
      paymentType: string[];
      status: string[];
      createdAt: [Dayjs, Dayjs];
    }
  >({
    resource: 'orders',
    initialSorter: [
      {
        field: 'updatedAt',
        order: 'desc',
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { orderCode, createdAt, status, paymentType } = params;
      filters.push(
        {
          operator: 'eq',
          field: 'orderCode',
          value: orderCode || undefined,
        },
        {
          operator: 'in',
          field: 'paymentType',
          value:
            paymentType && paymentType.length > 0 ? paymentType : undefined,
        },
        {
          field: 'status',
          operator: 'in',
          value: status && status.length > 0 ? status : undefined,
        },
        {
          field: 'createdAt',
          operator: 'gte',
          value: createdAt ? createdAt[0].toISOString() : undefined,
        },
        {
          field: 'createdAt',
          operator: 'lte',
          value: createdAt ? createdAt[1].toISOString() : undefined,
        }
      );

      return filters;
    },
  });
  return (
    <List>
      <Row gutter={[16, 16]}>
        <Col lg={6} xs={24}>
          <Card title="Filters">
            <Form layout="vertical" {...searchFormProps}>
              <Form.Item label="Search" name="orderCode">
                <Input
                  allowClear
                  placeholder="Order Code"
                  prefix={<SearchOutlined />}
                />
              </Form.Item>
              <Form.Item label="Payment Type" name="paymentType">
                <Select
                  allowClear
                  mode="multiple"
                  options={[
                    {
                      label: 'STRIPE CARD',
                      value: 'STRIPE_CARD',
                    },
                    {
                      label: 'STRIPE US BANK ACCOUNT',
                      value: 'STRIPE_US_BANK_ACCOUNT',
                    },
                    {
                      label: 'CRYPTO',
                      value: 'CRYPTO',
                    },
                  ]}
                  placeholder="Payment Type"
                />
              </Form.Item>
              <Form.Item label="Status" name="status">
                <Select
                  allowClear
                  mode="multiple"
                  options={[
                    // TODO from api
                    {
                      label: 'PENDING',
                      value: 'PENDING',
                    },
                    {
                      label: 'PAID',
                      value: 'PAID',
                    },
                    {
                      label: 'COMPLETED',
                      value: 'COMPLETED',
                    },
                    {
                      label: 'EXPIRED',
                      value: 'EXPIRED',
                    },
                    {
                      label: 'PAYMENT PROCESSING',
                      value: 'PAYMENT_PROCESSING',
                    },
                    {
                      label: 'PAYMENT FAIL',
                      value: 'PAYMENT_FAIL',
                    },
                    {
                      label: 'REFUNDED',
                      value: 'REFUNDED',
                    },
                    {
                      label: 'REFUND PENDING',
                      value: 'REFUND_PENDING',
                    },
                    {
                      label: 'REFUND FAILED',
                      value: 'REFUND_FAILED',
                    },
                  ]}
                  placeholder="Status"
                />
              </Form.Item>
              <Form.Item label="Created At" name="createdAt">
                <RangePicker />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Filter
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col lg={18} xs={24}>
          <Table {...tableProps} rowKey="id">
            <Table.Column
              dataIndex="orderCode"
              title="Order Code"
              key="orderCode"
            />
            <Table.Column
              dataIndex="paymentType"
              title="Payment Type"
              key="paymentType"
              render={(value) => (
                <TextField value={value.replaceAll('_', ' ')} />
              )}
            />
            <Table.Column
              align="right"
              key="price"
              dataIndex="price"
              title={'Price'}
              render={(value) => {
                return (
                  <NumberField
                    options={{
                      currency: 'USD',
                      style: 'currency',
                      notation: 'compact',
                    }}
                    value={value}
                  />
                );
              }}
            />
            <Table.Column
              align="right"
              key="amount"
              dataIndex="amount"
              title={'Amount'}
              // defaultSortOrder={getDefaultSortOrder('amount', sorter)}
              sorter
              render={(value) => {
                return <NumberField value={value} />;
              }}
            />
            <Table.Column key="status" dataIndex={'status'} title={'Status'} />
            <Table.Column
              key="paidAt"
              dataIndex="paidAt"
              title={'Paid At'}
              render={(value) =>
                value ? <DateField value={value} format="LLL" /> : <></>
              }
              sorter
            />
            <Table.Column
              dataIndex="updatedAt"
              title="Updated At"
              key="updatedAt"
              sorter
              defaultSortOrder={getDefaultSortOrder('updatedAt', sorter)}
              render={(value) => <DateField value={value} format="LLL" />}
            />
            <Table.Column
              dataIndex="createdAt"
              title="Created At"
              key="createdAt"
              sorter
              render={(value) => <DateField value={value} format="LLL" />}
            />
            <Table.Column<Order>
              title="Actions"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <ShowButton
                    hideText
                    size="small"
                    recordItemId={record.orderCode}
                  />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
    </List>
  );
};
