const formatter = new Intl.NumberFormat('en-US');

export const numberFormat = (number: number) => {
  return formatter.format(number);
};

export const parseLocale$Number = (stringNumber: string) => {
  return Number(stringNumber!.replace(/\$\s?|(,*)/g, ''));
};

export const parseLocaleNumberPercentage = (stringNumber: string) => {
  return Number(stringNumber!.replace('%', ''));
};
