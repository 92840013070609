import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import type { FormInstance } from 'antd/es/form';
import classNames from 'classnames';

interface Props {
  form: FormInstance<any>;
  isShow: boolean;
  isDraft?: boolean;
  diff: any;
}

export default function SettingsFormItems({ isShow, isDraft }: Props) {
  return (
    <div
      className={classNames('hidden', {
        '!block': isShow,
      })}
    >
      <Form.Item
        name="urlHandle"
        label="URL Handle"
        tooltip={{
          icon: <InfoCircleOutlined />,
          title:
            'The URL handle is a sequence of characters that is included in the website\'s URL. Only include letters (a-z, A-Z), numbers (0-9), hyphens ("-"), and underscores ("_"). Avoid using spaces and special characters (such as "#", "$", "%", etc.).',
        }}
        rules={[{ required: isShow }]}
      >
        <Input disabled={!isDraft} />
      </Form.Item>
      <Form.Item
        name="tokenName"
        label="Token Name"
        tooltip={{
          icon: <InfoCircleOutlined />,
          title:
            'Token Name is a descriptive name for the token, up to 255 characters in length. ',
        }}
        rules={[{ required: isShow }]}
      >
        <Input disabled={!isDraft} />
      </Form.Item>
      <Form.Item
        name="tokenSymbol"
        label="Token Symbol"
        tooltip={{
          icon: <InfoCircleOutlined />,
          title:
            'Token Symbol is a short code to represent the token, up to 255 characters in length.',
        }}
        rules={[{ required: isShow }]}
      >
        <Input disabled={!isDraft} />
      </Form.Item>
    </div>
  );
}
