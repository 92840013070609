import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';

interface Props {
  value: string;
  onSave: (label: string) => void;
  remove: (index: number | number[]) => void;
  className?: string;
  formName: number;
}

export default function CustomFieldLabel({
  value,
  className,
  formName,
  onSave,
  remove,
}: Props) {
  const [label, setLabel] = useState<string>('Custom Field');
  const [isSave, setIsSave] = useState<boolean>(false);
  return (
    <>
      <div
        className={classNames('flex gap-2 items-center', className, {
          '!hidden': isSave || value,
        })}
      >
        <Input
          value={label}
          onChange={(e) => {
            setLabel(e.target.value);
          }}
          placeholder="label"
        />
        <Button
          onClick={() => {
            onSave(label);
            setIsSave(true);
          }}
        >
          Save
        </Button>
      </div>
      <div
        className={classNames('flex gap-2 items-center', {
          '!hidden': !isSave && !value,
        })}
      >
        <span>{value || label}</span>
        <MinusCircleOutlined onClick={() => remove(formName)} />
      </div>
    </>
  );
}
