import {
  FileImageOutlined,
  FileTextOutlined,
  ProjectOutlined,
  ShoppingOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { PageEdit, PageList } from 'pages/contents';
import { DocumentList } from 'pages/documents';
import { DocumentShow } from 'pages/documents/show';
import { GroupCreate } from 'pages/groups/create';
import { GroupList } from 'pages/groups/list';
import { GroupShow } from 'pages/groups/show';
import { MemberList, MemberShow } from 'pages/members';
import { OrderList, OrderShow } from 'pages/orders';
import {
  ProjectCreate,
  ProjectEdit,
  ProjectList,
  ProjectShow,
} from 'pages/projects';
import { UserList } from 'pages/users';
import { UserCreate } from 'pages/users/create';
import { UserShow } from 'pages/users/show';

export const commonResources = [
  {
    name: 'members',
    icon: <UserOutlined />,
    options: {
      dataProviderName: 'crud',
      label: 'Investors',
      route: 'investors',
    },
    list: MemberList,
    show: MemberShow,
  },
  {
    name: 'projects',
    icon: <ProjectOutlined />,
    options: { dataProviderName: 'crud' },
    list: ProjectList,
    create: ProjectCreate,
    edit: ProjectEdit,
    show: ProjectShow,
  },
  {
    parentName: 'projects',
    name: 'distributions',
  },
  {
    name: 'orders',
    icon: <ShoppingOutlined />,
    options: {
      dataProviderName: 'crud',
      label: 'Transactions',
      route: 'transactions',
    },
    list: OrderList,
    show: OrderShow,
  },
  {
    name: 'document',
    icon: <FileTextOutlined />,
    options: { dataProviderName: 'crud' },
    list: DocumentList,
    show: DocumentShow,
  },
];

export const adminResources = [
  {
    name: 'content',
    icon: <FileImageOutlined />,
    // list: ContentManager,
  },
  {
    name: 'single-types',
    parentName: 'content',
    icon: <FileTextOutlined />,
    list: PageList,
    edit: PageEdit,
    options: {
      label: 'Pages',
      dataProviderName: 'strapiV4ContentManager',
    },
  },
  // {
  //   name: 'settings',
  //   icon: <SettingOutlined />,
  // },
  // {
  //   name: 'chains',
  //   parentName: 'settings',
  //   icon: <BlockOutlined />,
  //   options: { dataProviderName: 'crud' },
  //   list: ChainList,
  //   create: ChainCreate,
  //   edit: ChainEdit,
  // },
  // {
  //   name: 'wallets',
  //   parentName: 'settings',
  //   icon: <WalletOutlined />,
  //   options: { dataProviderName: 'crud' },
  //   list: WalletList,
  //   create: WalletCreate,
  // },

  // {
  //   name: 'documents',
  //   parentName: 'settings',
  //   icon: <FileTextOutlined />,
  //   list: SettingDocumentList,
  // },
  // {
  //   name: 'payments',
  //   parentName: 'settings',
  //   icon: <FileTextOutlined />,
  //   list: SettingPaymentList,
  // },
  {
    name: 'users',
    icon: <UserAddOutlined />,
    options: { dataProviderName: 'customAPI' },
    list: UserList,
    create: UserCreate,
    show: UserShow,
  },
  {
    name: 'groups',
    icon: <UsergroupAddOutlined />,
    // options: { dataProviderName: 'customAPI' },
    options: { dataProviderName: 'crud' },
    list: GroupList,
    create: GroupCreate,
    show: GroupShow,
  },
];
