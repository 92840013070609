import { Upload } from '@pankod/refine-antd';
import { useStrapiUpload } from '@pankod/refine-strapi-v4';

import classes from './styles/index.module.scss';

export default function UploadImage(args: any) {
  const accessToken = localStorage.getItem('accessToken');
  const { ...uploadProps } = useStrapiUpload({
    maxCount: args.multiple ? 10 : 1,
  });

  const uploadButton = (
    <div className="p-4 !w-full cursor-pointer text-center bg-[#fbfbfb] transition border-dashed border rounded-lg border-[#d9d9d9] hover:border-[#4096ff]">
      <div className="text-base">Drag & drop a file in this area</div>
    </div>
  );

  return (
    <>
      {args.multiple && (
        <Upload.Dragger
          name="files"
          action={`${process.env.REACT_APP_BASE_API_URL}/v1/admin/cms/upload`}
          headers={{
            Authorization: `Bearer ${accessToken}`,
          }}
          listType="picture"
          {...uploadProps}
          {...args}
        >
          <p className="ant-upload-text">Drag & drop a file in this area</p>
        </Upload.Dragger>
      )}
      {!args.multiple && (
        <Upload
          className={classes.upload}
          name="files"
          listType="picture"
          action={`${process.env.REACT_APP_BASE_API_URL}/v1/admin/cms/upload`}
          headers={{
            Authorization: `Bearer ${accessToken}`,
          }}
          {...uploadProps}
          {...args}
        >
          {!(
            args?.fileList?.[0]?.url || args?.fileList?.[0]?.response?.[0].url
          ) && uploadButton}
        </Upload>
      )}
    </>
  );
}
