import {
  Checkbox,
  Create,
  Form,
  Input,
  Select,
  useForm,
} from '@pankod/refine-antd';
import type { IResourceComponentsProps } from '@pankod/refine-core';
import { useQuery } from '@pankod/refine-core';
import { adminGroupManagementAdminApi } from 'api/coreBackend/defHttp';
import { useEffect } from 'react';

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();
  // const [roleId, setRoleId] = useState(1);

  const { data } = useQuery(
    ['adminGroupManagementAdminApi.getManyBaseAdminGroupControllerAdminGroup'],
    () => {
      return adminGroupManagementAdminApi.getManyBaseAdminGroupControllerAdminGroup();
    }
  );

  useEffect(() => {
    // TODO: 之後需要改用 antd 修改
    const doms = document.querySelectorAll('.ant-breadcrumb-link');
    doms[1].textContent = 'Invite';
  }, []);

  return (
    <Create
      title="Invite New User"
      isLoading={queryResult?.isFetching}
      saveButtonProps={{ ...saveButtonProps, children: 'Send' }}
    >
      <Form
        {...formProps}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 800 }}
        initialValues={{
          roleId: 1,
          groupIds: [],
        }}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label="Role"
          name="roleId"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              { value: 2, label: 'Sponsor' },
              { value: 1, label: 'Administrator' },
            ]}
          />
        </Form.Item>
        {/* <Form.Item
          hidden={roleId === 1}
          label="Projects Permission"
          name="projectIds"
        >
          <Checkbox.Group
            options={meData?.data.projectPermissions.map((project) => ({
              label: project.projectId,
              value: project.projectId,
            }))}
          />
        </Form.Item> */}
        <Form.Item label="Groups Permission" name="groupIds">
          <Checkbox.Group
            options={(data?.data as any[])?.map((group) => ({
              label: group.name,
              value: group.id,
            }))}
          />
          {/* <Select
            // onChange={setRoleId}
            options={(data?.data as any[])?.map((group) => ({
              value: group.id,
              label: group.name,
            }))}
          /> */}
        </Form.Item>
      </Form>
    </Create>
  );
};
