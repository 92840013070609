import {
  List,
  ShowButton,
  Space,
  Table,
  Tag,
  useTable,
} from '@pankod/refine-antd';
import type { IResourceComponentsProps } from '@pankod/refine-core';
import { useQuery } from '@pankod/refine-core';
import { adminGroupManagementAdminApi } from 'api/coreBackend/defHttp';
import dayjs from 'dayjs';

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable();
  const { data: groupsRes } = useQuery(
    ['adminGroupManagementAdminApi.getManyBaseAdminGroupControllerAdminGroup'],
    () => {
      return adminGroupManagementAdminApi.getManyBaseAdminGroupControllerAdminGroup();
    }
  );

  const groupsData = groupsRes?.data as any;

  return (
    <List createButtonProps={{ children: 'Invite' }}>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="email" title="Email" />
        <Table.Column
          title="Role"
          render={(_, record: { roleId: number }) => {
            return record.roleId === 1 ? 'Administrator' : 'Sponsor';
          }}
        />
        <Table.Column
          dataIndex="groupIds"
          title="Groups"
          render={(value: number[]) => {
            return value.length !== 0
              ? value.map((group) => (
                  <Tag color="blue">
                    {groupsData?.find((item: any) => item.id === group)?.name}
                  </Tag>
                ))
              : '--';
          }}
        />
        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(value) => dayjs(value).format('LLL')}
        />
        <Table.Column
          dataIndex="updatedAt"
          title="Updated At"
          render={(value) => dayjs(value).format('LLL')}
        />
        <Table.Column
          dataIndex="status"
          title="Status"
          render={(value: string) => {
            // PENDING_SUBMIT = 'PENDING_SUBMIT',
            // PENDING_REVIEW = 'PENDING_REVIEW',
            // APPROVED = 'APPROVED',
            // REJECTED = 'REJECTED',
            if (value === 'PENDING_SUBMIT') {
              return 'Invite Sent';
            }
            if (value === 'PENDING_REVIEW') {
              return 'Pending Review';
            }
            return value
              .toLocaleLowerCase()
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase()
              );
          }}
        />
        <Table.Column
          title="Actions"
          dataIndex="id"
          render={(value) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={value} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
