import type { FormInstance } from 'antd';
import { Form, InputNumber } from 'antd';
import { useEffect } from 'react';
import type { ProjectForm } from 'views/projects/model/project';

import { ProjectStep } from '../../enum/steps';
import FullDescriptionsFormItems from './containers/FullDescriptionsFormItems';
import ImageAndDocumentsFormItems from './containers/ImageAndDocumentsFormItems';
import ProjectDetailsFormItems from './containers/ProjectDetailsFormItems';
import SaleDetailsFormItems from './containers/SaleDetailsFormItems';
import SettingsFormItems from './containers/SettingsFormItems';
import TargetedReturnsAndDistributionsFormItems from './containers/TargetedReturnsAndDistributionsFormItems';

interface Props {
  form: FormInstance<any>;
  step: ProjectStep;
  initialValues?: Partial<ProjectForm>;
  disabled?: boolean;
  isDraft?: boolean;
  diff?: any;
}

export function FormList({
  step,
  form,
  initialValues,
  disabled = false,
  isDraft,
  diff,
}: Props) {
  const totalFundraising = Form.useWatch('totalFundraising', form);
  const pricePerToken = Form.useWatch('pricePerToken', form);
  useEffect(() => {
    form.setFieldsValue({
      totalTokens: totalFundraising / pricePerToken,
    });
    form.validateFields(['totalTokens']);
  }, [totalFundraising, pricePerToken]);

  return (
    <>
      <Form
        disabled={disabled}
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
      >
        <Form.Item className="hidden" name="decimals">
          <InputNumber />
        </Form.Item>
        <ProjectDetailsFormItems
          form={form}
          isShow={step === ProjectStep.PROJECT_DETAILS}
          isDraft={isDraft}
          diff={diff}
        />
        <SaleDetailsFormItems
          form={form}
          isShow={step === ProjectStep.SALE_DETAILS}
          isDraft={isDraft}
          diff={diff}
        />
        <TargetedReturnsAndDistributionsFormItems
          form={form}
          isShow={step === ProjectStep.TARGETED_RETURNS_AND_DISTRIBUTIONS}
          isDraft={isDraft}
          diff={diff}
        />
        <FullDescriptionsFormItems
          form={form}
          isShow={step === ProjectStep.FULL_DESCRIPTIONS}
          isDraft={isDraft}
          diff={diff}
        />
        <ImageAndDocumentsFormItems
          form={form}
          isShow={step === ProjectStep.IMAGES_AND_DOCUMENTS}
          isDraft={isDraft}
          diff={diff}
        />
        <SettingsFormItems
          form={form}
          isShow={step === ProjectStep.SETTINGS}
          isDraft={isDraft}
          diff={diff}
        />
      </Form>
    </>
  );
}
