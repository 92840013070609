import '../style/styles.css';

import {
  AntdLayout,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from '@pankod/refine-antd';
import { useLogin } from '@pankod/refine-core';
import { queryClient } from 'index';
import React from 'react';

const { Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const Login: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>();

  const { mutate: login } = useLogin<ILoginForm>({
    mutationOptions: {
      onSettled: () => {
        queryClient.invalidateQueries(['adminAuthenticationApi.adminGetMe']);
      },
    },
  });

  const CardTitle = (
    <Title level={3} className="title">
      Sign in your account
    </Title>
  );

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img
                className="h-52"
                src="/images/logo/logo-white.svg"
                alt="talfa Logo"
              />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login(values);
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="username"
                  label="Email"
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="Email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true }]}
                  style={{ marginBottom: '12px' }}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <div style={{ marginBottom: '12px' }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{
                        fontSize: '12px',
                      }}
                    >
                      Remember me
                    </Checkbox>
                  </Form.Item>

                  {/* <a
                    style={{
                      float: 'right',
                      fontSize: '12px',
                    }}
                    href="#"
                  >
                    Forgot password?
                  </a> */}
                </div>
                <Button type="primary" size="large" htmlType="submit" block>
                  Sign in
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
