import { Breadcrumb, PageHeader } from '@pankod/refine-antd';
import type { ResourceRouterParams } from '@pankod/refine-core';
import { useNavigation, useQuery, useRouterContext } from '@pankod/refine-core';
import type { CreateProjectBodyDto } from '@tokenbricks/talfa-backend-typescript-axios-client';
import {
  AdminProjectAoStatusEnum,
  ProjectApplicationAoStatusEnum,
} from '@tokenbricks/talfa-backend-typescript-axios-client';
import { Button, Card, Form, Steps } from 'antd';
import {
  adminAuthenticationApi,
  projectManagementAdminApi,
} from 'api/coreBackend/defHttp';
import { useMemo, useState } from 'react';
import { convertCmsImageData } from 'utils/convertCmsImageData';
import ApprovalTimelineButton from 'views/projects/containers/ApprovalTimelineButton';
import ApproveAndPublishButton from 'views/projects/containers/ApproveAndPublishButton';
import { FormList } from 'views/projects/containers/FormList';
import RejectButton from 'views/projects/containers/RejectButton';
import SetPublishButton from 'views/projects/containers/SetPublishButton';
import SubmitForApprovalButton from 'views/projects/containers/SubmitForApprovalButton';
import { formStepsFields } from 'views/projects/data/form';
import { projectSteps } from 'views/projects/data/steps';
import { ProjectStep } from 'views/projects/enum/steps';
import { useProjectMethod } from 'views/projects/hooks/useProjectMethod';
import type {
  ProjectCMSContent,
  ProjectForm,
} from 'views/projects/model/project';
import { compareJSON } from 'views/projects/utils/compareJSON';
import {
  convertProjectApiBodyDtoToProjectForm,
  convertProjectApiCreateBodyDtoToProjectForm,
} from 'views/projects/utils/projects';

export const ProjectEdit: React.FC = () => {
  const { useParams } = useRouterContext();
  const { id: projectId } = useParams<ResourceRouterParams>();
  const { goBack } = useNavigation();
  const { updateDraftProjectMutation, updatePublishedProjectMutation } =
    useProjectMethod();
  const [currentStep, setCurrentStep] = useState(ProjectStep.PROJECT_DETAILS);
  const [completedSteps, setCompletedSteps] = useState<ProjectStep[]>([
    ProjectStep.PROJECT_DETAILS,
    ProjectStep.SALE_DETAILS,
    ProjectStep.TARGETED_RETURNS_AND_DISTRIBUTIONS,
    ProjectStep.FULL_DESCRIPTIONS,
    ProjectStep.IMAGES_AND_DOCUMENTS,
    ProjectStep.SETTINGS,
  ]);
  const [form] = Form.useForm();

  const onChangeStep = (value: ProjectStep) => {
    if (completedSteps.includes(value)) {
      if (completedSteps.includes(currentStep)) {
        form.validateFields(formStepsFields[currentStep]).then(() => {
          setCurrentStep(value);
        });
      } else {
        setCurrentStep(value);
      }
    } else {
      form.validateFields(formStepsFields[currentStep]).then(() => {
        if (
          value === ProjectStep.SETTINGS &&
          completedSteps.includes(ProjectStep.IMAGES_AND_DOCUMENTS)
        ) {
          setCurrentStep(value);
        } else {
          setCurrentStep(currentStep + 1);
        }
        setCompletedSteps([...completedSteps, currentStep]);
      });
    }
  };

  const { data: user } = useQuery(['adminAuthenticationApi.adminGetMe'], () => {
    return adminAuthenticationApi.adminGetMe();
  });
  const isAdmin = user?.data.roleId === 1;
  const { data: projectData, isLoading: isLoadingProjectData } = useQuery(
    [
      'projectManagementAdminApi.getOneBaseAdminProjectControllerProject',
      projectId,
    ],
    () => {
      return projectManagementAdminApi.getOneBaseAdminProjectControllerProject(
        Number(projectId)
      );
    },
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const {
    data: projectApplicationData,
    isLoading: isLoadingProjectApplicationData,
  } = useQuery(
    [
      'projectManagementAdminApi.getOneBaseAdminProjectApplicationControllerProjectApplication',
      projectData?.data.latestProjectApplicationId,
    ],
    () => {
      if (projectData?.data.latestProjectApplicationId)
        return projectManagementAdminApi.getOneBaseAdminProjectApplicationControllerProjectApplication(
          projectData?.data.latestProjectApplicationId
        );
      return undefined;
    },
    {
      enabled: Boolean(projectData?.data.latestProjectApplication),
    }
  );

  const isLoading =
    isLoadingProjectData ||
    (Boolean(projectData?.data.latestProjectApplication) &&
      isLoadingProjectApplicationData);

  const handleNext = () => {
    onChangeStep(currentStep + 1);
  };

  const handlePrevious = () => {
    onChangeStep(currentStep - 1);
  };

  const handleSaveAsDraft = () => {
    updateDraftProjectMutation.mutate({
      id: Number(projectId),
      data: form.getFieldsValue(),
    });
  };

  const handleSaveAsChange = () => {
    const firstUpdate =
      projectData?.data.latestProjectApplication?.status !==
      ProjectApplicationAoStatusEnum.DRAFT;
    updatePublishedProjectMutation.mutate({
      firstUpdate,
      id: firstUpdate
        ? Number(projectId)
        : Number(projectData?.data.latestProjectApplication?.id),
      data: form.getFieldsValue(),
    });
  };

  const initialValues: ProjectForm | undefined = useMemo(() => {
    let values;
    if (projectData?.data.status === AdminProjectAoStatusEnum.DRAFT) {
      values = convertProjectApiBodyDtoToProjectForm(projectData.data);
    } else if (
      projectApplicationData?.data.data &&
      projectData?.data.latestProjectApplication?.status !==
        ProjectApplicationAoStatusEnum.APPROVED
    ) {
      values = convertProjectApiCreateBodyDtoToProjectForm(
        projectApplicationData.data.data as CreateProjectBodyDto & {
          content: ProjectCMSContent;
        }
      );
    } else if (projectData?.data) {
      values = convertProjectApiBodyDtoToProjectForm(projectData.data);
    }

    return convertCmsImageData(values);
  }, [
    projectData,
    projectApplicationData,
    projectData?.data.latestProjectApplication?.status,
  ]);

  const diff = useMemo(() => {
    if (projectData?.data && initialValues && !isLoading) {
      return compareJSON(
        initialValues,
        convertCmsImageData(
          convertProjectApiBodyDtoToProjectForm(projectData.data)
        )
      );
    }
    return {};
  }, [initialValues, projectData, isLoading]);
  // console.log('diff', diff);
  // console.log('projectData', projectData);
  // console.log('initialValues', initialValues);

  return (
    <div>
      <PageHeader
        title="Edit Project"
        extra={
          <>
            {projectData && (
              <div className="flex items-center gap-2 mr-4">
                <svg
                  width={6}
                  height={6}
                  viewBox="0 0 6 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="flex-grow-0 flex-shrink-0"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <circle cx={3} cy={3} r={3} fill="#7264E6" />
                </svg>
                <p className="text-sm text-[#393939] mb-0">
                  Status:{' '}
                  <span className="capitalize">
                    {projectData?.data?.status.toLocaleLowerCase()} /{' '}
                    {projectData?.data?.latestProjectApplication?.status.toLocaleLowerCase() ||
                      '--'}
                  </span>
                </p>
              </div>
            )}
            {projectData?.data?.latestProjectApplication && (
              <ApprovalTimelineButton projectId={Number(projectId)} />
            )}
            {isAdmin &&
              projectData &&
              projectData?.data?.status !== AdminProjectAoStatusEnum.DRAFT && (
                <SetPublishButton
                  projectId={Number(projectId)}
                  isPublish={
                    projectData?.data?.status ===
                    AdminProjectAoStatusEnum.PUBLISHED
                  }
                />
              )}
            {isAdmin && projectData && (
              <>
                <RejectButton
                  projectId={Number(projectId)}
                  projectApplicationId={
                    projectData?.data?.latestProjectApplicationId
                  }
                  disabled={
                    projectData?.data?.latestProjectApplication?.status !==
                    ProjectApplicationAoStatusEnum.SUBMITTED
                  }
                />
                <ApproveAndPublishButton
                  projectId={Number(projectId)}
                  projectApplicationId={
                    projectData?.data?.latestProjectApplicationId
                  }
                  disabled={
                    projectData?.data?.latestProjectApplication?.status !==
                    ProjectApplicationAoStatusEnum.SUBMITTED
                  }
                />
              </>
            )}
          </>
        }
        breadcrumb={<Breadcrumb />}
        onBack={goBack}
      />
      <div className="mt-6 flex">
        <div className="w-80">
          <Steps
            direction="vertical"
            current={currentStep}
            onChange={onChangeStep}
            items={projectSteps}
          />
        </div>
        <div className="flex-1">
          <Card className="w-full px-12 py-5" loading={isLoading}>
            {!isLoading && (
              <FormList
                form={form}
                step={currentStep}
                isDraft={
                  projectData?.data?.status === AdminProjectAoStatusEnum.DRAFT
                }
                initialValues={initialValues}
                diff={diff}
              />
            )}
          </Card>
          <div className="mt-4 text-right">
            {/* <Button
              className="mr-4"
              onClick={() => {
                console.log(form.getFieldsValue());
              }}
            >
              Print Form Value (Dev)
            </Button> */}
            {currentStep !== ProjectStep.PROJECT_DETAILS && (
              <Button onClick={handlePrevious}>Previous</Button>
            )}
            <SubmitForApprovalButton
              className="ml-4"
              projectId={Number(projectId)}
              projectApplicationId={Number(
                projectData?.data?.latestProjectApplicationId
              )}
              projectForm={form}
              isFirstSubmit={
                projectData?.data?.status === AdminProjectAoStatusEnum.DRAFT
              }
              disabled={
                projectData?.data?.latestProjectApplication?.status ===
                  ProjectApplicationAoStatusEnum.APPROVED ||
                projectData?.data?.latestProjectApplication?.status ===
                  ProjectApplicationAoStatusEnum.SUBMITTED
              }
            />
            {projectData?.data?.status === AdminProjectAoStatusEnum.DRAFT ? (
              <Button
                className="ml-4"
                type="primary"
                onClick={handleSaveAsDraft}
                loading={updateDraftProjectMutation.isLoading}
                disabled={
                  projectData?.data?.latestProjectApplication?.status ===
                  ProjectApplicationAoStatusEnum.SUBMITTED
                }
              >
                Save as Draft
              </Button>
            ) : (
              <Button
                className="ml-4"
                type="primary"
                onClick={handleSaveAsChange}
                loading={updatePublishedProjectMutation.isLoading}
                disabled={
                  projectData?.data?.latestProjectApplication?.status ===
                  ProjectApplicationAoStatusEnum.SUBMITTED
                }
              >
                Save Changes
              </Button>
            )}
            {currentStep !== ProjectStep.SETTINGS && (
              <Button className="ml-4" onClick={handleNext}>
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
