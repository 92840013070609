import { ErrorComponent, ReadyPage } from '@pankod/refine-antd';
import { Refine, useQuery } from '@pankod/refine-core';
import { RefineKbarProvider } from '@pankod/refine-kbar';
import nestjsCrudDataProvider from '@pankod/refine-nestjsx-crud';
import routerProvider from '@pankod/refine-react-router-v6';
import dataProvider from '@pankod/refine-simple-rest';
import { DataProvider as strapiV4DataProvider } from '@pankod/refine-strapi-v4';
import {
  CreateAdminProjectPermissionBodyDtoActionEnum,
  CreateAdminProjectPermissionBodyDtoResourceEnum,
} from '@tokenbricks/talfa-backend-typescript-axios-client';
import {
  adminApplicationManagementAdminApi,
  adminAuthenticationApi,
  coreBackendDefHttp,
  documentManagementAdminApi,
  paymentProviderSettingAdminApi,
  projectManagementAdminApi,
} from 'api/coreBackend/defHttp';
import { authProvider } from 'authProvider';
import { Header } from 'components/layout';
import { CustomLayout } from 'components/layout/layout';
import { OffLayoutArea } from 'components/OffLayoutArea';
import DynamicChainProvider from 'containers/DynamicChainProvider';
import { adminResources, commonResources } from 'data/resources';
import { CreatePassword } from 'pages/auth/create-password';
import { Login } from 'pages/auth/login';
import { Register } from 'pages/auth/register';
import { SetNameSuccess } from 'pages/auth/success';
import Personal from 'pages/personal';
import { ProjectSetting } from 'pages/projects/setting/[id]';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { customize } from 'utils/custom-data-provider';
import { customNotificationProvider } from 'utils/customNotificationProvider';

import { Sider } from './components/layout';

const { REACT_APP_BASE_API_URL } = process.env;

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const { data: user } = useQuery(['adminAuthenticationApi.adminGetMe'], () => {
    return adminAuthenticationApi.adminGetMe();
  });

  const isAdmin = user?.data.roleId === 1;

  return (
    <DynamicChainProvider>
      <DndProvider backend={HTML5Backend}>
        <RefineKbarProvider>
          <Refine
            notificationProvider={customNotificationProvider}
            Layout={CustomLayout}
            Sider={Sider}
            ReadyPage={ReadyPage}
            catchAll={<ErrorComponent />}
            routerProvider={{
              ...routerProvider,
              routes: [
                {
                  element: <ProjectSetting />,
                  path: '/projects/setting/:id',
                  layout: true,
                },
                {
                  element: <Register />,
                  path: '/auth/register/:roleId',
                },
                {
                  element: <CreatePassword />,
                  path: '/auth/create-password',
                },
                {
                  element: <SetNameSuccess />,
                  path: '/auth/set-name/success',
                },
                {
                  element: <Personal />,
                  path: '/personal',
                  layout: true,
                },
              ],
            }}
            authProvider={authProvider}
            dataProvider={{
              default: dataProvider(REACT_APP_BASE_API_URL, coreBackendDefHttp),
              strapiV4ContentManager: customize(
                dataProvider(
                  `${REACT_APP_BASE_API_URL}/v1/admin/cms/content-manager`,
                  coreBackendDefHttp
                ),
                {
                  'content-types': {
                    getList: async ({ resource, filters }) => {
                      const { data } = await coreBackendDefHttp.get(
                        `${REACT_APP_BASE_API_URL}/v1/admin/cms/content-manager/${resource}`
                      );
                      if (!data || !data.data) {
                        return {
                          data: [],
                          total: 0,
                        };
                      }
                      const kindEqFilter = filters?.find(
                        (filter) =>
                          filter.operator === 'eq' && filter.field === 'kind'
                      );
                      const contentTypes = kindEqFilter
                        ? data.data.filter(
                            (ct: any) => ct.kind === kindEqFilter.value
                          )
                        : data.data;
                      return {
                        data: contentTypes,
                        total: contentTypes.length,
                      };
                    },
                    getOne: async ({ resource, id }) => {
                      const { data } = await coreBackendDefHttp.get(
                        `${REACT_APP_BASE_API_URL}/v1/admin/cms/content-manager/${resource}`
                      );
                      if (!data || !data.data) {
                        return { data: undefined };
                      }
                      const one = data.data.find((ct: any) => ct.uid === id);
                      return { data: one };
                    },
                  },
                  'single-types': {
                    update: async ({ resource, id, variables }) => {
                      const url = `${REACT_APP_BASE_API_URL}/v1/admin/cms/content-manager/${resource}/${id}`;
                      const { data } = await coreBackendDefHttp.put(
                        url,
                        variables
                      );

                      return {
                        data,
                      };
                    },
                  },
                  'content-types/configuration': {
                    getOne: async ({ resource, id }) => {
                      const [resourcePath, configSlug] = resource.split('/');
                      const url = `${REACT_APP_BASE_API_URL}/v1/admin/cms/content-manager/${resourcePath}/${id}/${configSlug}`;
                      const { data } = await coreBackendDefHttp.get(url);
                      return {
                        data,
                      };
                    },
                  },
                  components: {
                    getMany: async ({ ids }) => {
                      const url = `${REACT_APP_BASE_API_URL}/v1/admin/cms/content-manager/components`;
                      const { data } = await coreBackendDefHttp.get(url);
                      const components = data.data.filter(
                        (component: { uid: string }) =>
                          ids.includes(component.uid)
                      );
                      return {
                        data: components,
                      };
                    },
                  },
                }
              ),
              strapiV4: customize(
                strapiV4DataProvider(
                  `${REACT_APP_BASE_API_URL}/v1/admin/cms`,
                  coreBackendDefHttp
                )
              ),
              customAPI: customize(
                {},
                {
                  'setting-documents': {
                    getList: async () => {
                      const { data } =
                        (await documentManagementAdminApi.getManyBaseAdminDocumentSettingControllerZohoSignSetting()) as any;
                      return {
                        data,
                        total: data.length,
                      };
                    },
                  },
                  'setting-payments': {
                    getList: async () => {
                      const { data } =
                        (await paymentProviderSettingAdminApi.getPaymentProviders()) as any;
                      return {
                        data,
                        total: data.length,
                      };
                    },
                  },
                  shareholders: {
                    getList: async (params) => {
                      const securityTokenIdEqFilter = params.filters?.find(
                        (filter) =>
                          filter.operator === 'eq' &&
                          filter.field === 'securityTokenId'
                      );

                      if (!securityTokenIdEqFilter) {
                        return {
                          data: [],
                          total: 0,
                        };
                      }

                      const { data } =
                        await projectManagementAdminApi.adminGetSecurityTokenShareholders(
                          securityTokenIdEqFilter.value,
                          params.pagination?.pageSize,
                          params.pagination?.current
                        );

                      return {
                        data: data.data,
                        total: data.pageCount,
                      };
                    },
                  },
                  'document-templates': {
                    getList: async () => {
                      const { data } =
                        (await documentManagementAdminApi.getDocumentTemplates()) as any;
                      return {
                        data: data.templates,
                        total: data.templates.length,
                      };
                    },
                  },
                  users: {
                    getOne: async ({ id }) => {
                      const { data } =
                        await adminApplicationManagementAdminApi.getOneBaseAdminApplicationControllerAdminApplication(
                          Number(id)
                        );
                      return {
                        data,
                      };
                    },
                    getList: async () => {
                      const { data } =
                        (await adminApplicationManagementAdminApi.getManyBaseAdminApplicationControllerAdminApplication()) as any;
                      return {
                        data,
                        total: data.length,
                      };
                    },
                    create: async ({ variables }) => {
                      const { projectIds, email, roleId, groupIds } =
                        variables as {
                          projectIds: number[];
                          email: string;
                          roleId: number;
                          groupIds: number[];
                        };

                      await adminApplicationManagementAdminApi.createAdminApplication(
                        {
                          email,
                          projectPermissions:
                            projectIds?.map((projectId) => ({
                              projectId,
                              resource:
                                CreateAdminProjectPermissionBodyDtoResourceEnum.all,
                              action:
                                CreateAdminProjectPermissionBodyDtoActionEnum.manage,
                            })) ?? [],
                          callbackPath: `/auth/register/${roleId}`,
                          roleId,
                          groupIds,
                        }
                      );
                      return {
                        data: {},
                      };
                    },
                  },
                }
              ),
              crud: customize(
                nestjsCrudDataProvider(
                  `${REACT_APP_BASE_API_URL}/v1/admin`,
                  coreBackendDefHttp
                )
              ),
            }}
            LoginPage={Login}
            i18nProvider={i18nProvider}
            Header={Header}
            resources={
              isAdmin
                ? [...commonResources, ...adminResources]
                : [...commonResources]
            }
            Title={() => <div />}
            OffLayoutArea={OffLayoutArea}
          />
        </RefineKbarProvider>
      </DndProvider>
    </DynamicChainProvider>
  );
}

export default App;
