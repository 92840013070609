import { ProCard } from '@ant-design/pro-components';
import {
  Button,
  Col,
  CreateButton,
  DateField,
  EditButton,
  Form,
  getDefaultSortOrder,
  List,
  Modal,
  Row,
  Show,
  ShowButton,
  Space,
  Table,
  TextField,
  useDrawerForm,
  useModal,
  useModalForm,
  useTable,
} from '@pankod/refine-antd';
import type { ResourceRouterParams } from '@pankod/refine-core';
import { useQuery, useRouterContext, useShow } from '@pankod/refine-core';
import type {
  AdminProjectAo,
  SecurityTokenTransferEvent,
} from '@tokenbricks/talfa-backend-typescript-axios-client';
import { projectManagementAdminApi } from 'api/coreBackend/defHttp';
// import { Table } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { BigNumber, ethers } from 'ethers';
import RcResizeObserver from 'rc-resize-observer';
import { useEffect, useMemo, useState } from 'react';
import type { ProjectContent } from 'views/projects/model/project';
import { convertProjectApiBodyDtoToProjectForm } from 'views/projects/utils/projects';
import { usePrepareSendTransaction, useSendTransaction } from 'wagmi';

import {
  CreateDistribution,
  DividendList,
  EditDistribution,
} from '../../components/Distribution';
import { AddressLink, TransactionLink } from '../../components/Links';
import { numberFormat } from '../../utils/numberFormat';

dayjs.extend(duration);
dayjs.extend(relativeTime);
export const ProjectShow: React.FC = () => {
  const { useParams } = useRouterContext();
  const { id: projectId } = useParams<ResourceRouterParams>();
  const [, setResponsive] = useState(false);
  const { modalProps: editModalProps, formProps: editFormProps } =
    useModalForm<any>({
      action: 'edit',
      warnWhenUnsavedChanges: true,
    });
  const { config } = usePrepareSendTransaction({
    request: {
      to: '0x70Bf7b543650AeF03E50cA20B3B4221BaA63a849',
      value: BigNumber.from('100000000000000'),
    },
  });
  const { data, isLoading, isSuccess, sendTransaction } =
    useSendTransaction(config);

  const { queryResult } = useShow<AdminProjectAo>({
    dataProviderName: 'crud',
    resource: 'projects',
  });
  const project = queryResult.data?.data;

  const { tableProps: shareholderTableProps } = useTable({
    dataProviderName: 'customAPI',
    resource: 'shareholders',
    permanentFilter: [
      {
        field: 'securityTokenId',
        operator: 'eq',
        value: project?.securityToken?.id,
      },
    ],
  });
  const {
    tableProps: securityTokenTransferEventTableProps,
    sorter: securityTokenTransferEventSorter,
  } = useTable<SecurityTokenTransferEvent>({
    dataProviderName: 'crud',
    resource: 'security-token-transfer-events',
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
  });
  // write a useState for selected distribution
  const [selectedDistributionId, setSelectedDistributionId] =
    useState<number>(0);

  const { modalProps: dividendModalProps, show: dividendModalShow } =
    useModal();
  const { tableProps: distributionTableProps } = useTable<any>({
    dataProviderName: 'crud',
    resource: 'distributions',
    initialSorter: [{ field: 'distributionScheduleAt', order: 'desc' }],
  });
  const {
    drawerProps: createDistributionDrawerProps,
    formProps: createDistributionFormProps,
    saveButtonProps: createDistributionSaveButtonProps,
    show: createDistributionShow,
    onFinish: onFinishCreateDistribution,
    form: createDistributionForm,
  } = useDrawerForm<any>({
    action: 'create',
    dataProviderName: 'crud',
    resource: 'distributions',
    redirect: false,
  });
  const {
    drawerProps: editDistributionDrawerProps,
    formProps: editDistributionFormProps,
    saveButtonProps: editDistributionSaveButtonProps,
    show: editDistributionShow,
    onFinish: onFinishEditDistribution,
    form: editDistributionForm,
  } = useDrawerForm<any>({
    action: 'edit',
    dataProviderName: 'crud',
    resource: 'distributions',
    redirect: false,
  });

  const { data: projectData, isLoading: isLoadingProjectData } = useQuery(
    [
      'projectManagementAdminApi.getOneBaseAdminProjectControllerProject',
      projectId,
    ],
    () => {
      return projectManagementAdminApi.getOneBaseAdminProjectControllerProject(
        Number(projectId)
      );
    },
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const projectFromData = useMemo(() => {
    if (projectData)
      return convertProjectApiBodyDtoToProjectForm(projectData.data);
    return undefined;
  }, [projectData]);

  useEffect(() => {
    // TODO: 之後需要改用 antd 修改
    const doms = document.querySelectorAll('.ant-breadcrumb-link');
    doms[1].textContent = 'Manage';
  }, []);

  return (
    <Show
      headerButtons={<></>}
      isLoading={queryResult.isLoading || isLoadingProjectData}
      title="Manage Project"
      contentProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
    >
      <Row gutter={[16, 16]}>
        <Col xl={8} lg={24} xs={24}>
          <RcResizeObserver
            key="resize-observer"
            onResize={(offset) => {
              setResponsive(offset.width < 596);
            }}
          >
            <ProCard
              title={
                // @ts-ignore
                (project?.data?.content?.data as ProjectContent)?.attributes
                  .name
              }
              bordered
              headerBordered
              split={'horizontal'}
            >
              <ProCard className="text-lg font-semibold bg-[#fafafa]">
                Project Detail
              </ProCard>
              <ProCard title="Project Name">
                {projectFromData?.projectName}
              </ProCard>
              <ProCard title="Project Preview Description">
                {projectFromData?.projectDescription}
              </ProCard>
              <ProCard split="vertical">
                <ProCard className="capitalize" title="Investment Type">
                  {projectFromData?.investmentType}
                </ProCard>
                <ProCard className="capitalize" title="Property Type">
                  {projectFromData?.propertyType}
                </ProCard>
              </ProCard>
              <ProCard title="City, State | MSA">
                {projectFromData?.cityState}
              </ProCard>
              <ProCard title="Address">
                {projectFromData?.addresses.map((address) => (
                  <ProCard split="vertical" key={address.address}>
                    {address.address}
                  </ProCard>
                ))}
              </ProCard>
              <ProCard className="capitalize" title="Investment Strategy">
                {projectFromData?.investmentStrategy}
              </ProCard>
              <ProCard split="vertical">
                <ProCard className="capitalize" title="Initial Offering Date">
                  {dayjs(projectFromData?.initialDate[0]).format('LLL')}
                </ProCard>
                <ProCard className="capitalize" title="Initial Closing Date">
                  {dayjs(projectFromData?.initialDate[1]).format('LLL')}
                </ProCard>
              </ProCard>

              <ProCard className="text-lg font-semibold bg-[#fafafa]">
                Sale Details
              </ProCard>
              <ProCard split="vertical">
                <ProCard title="Total Fundraising">
                  {numberFormat(projectFromData?.totalFundraising ?? 0)}
                </ProCard>
                <ProCard title="Price Per Token">
                  {numberFormat(projectFromData?.pricePerToken ?? 0)}
                </ProCard>
              </ProCard>
              <ProCard split="vertical">
                <ProCard title="Total Tokens">
                  {numberFormat(projectFromData?.totalTokens ?? 0)}
                </ProCard>
                <ProCard title="Minimum Investment">
                  {numberFormat(projectFromData?.minimumInvestment ?? 0)}
                </ProCard>
              </ProCard>
              <ProCard split="vertical">
                <ProCard title="Minimum Holding Period">
                  {numberFormat(projectFromData?.minimumHoldingPeriod ?? 0)}{' '}
                  days
                </ProCard>
                <ProCard title="Sponsor Investment Period">
                  {projectFromData?.sponsorInvestmentPeriod}
                </ProCard>
              </ProCard>
              <ProCard split="vertical">
                <ProCard title="Sponsor Co-Invest">
                  ${projectFromData?.sponsorCoInvest$}
                </ProCard>
                <ProCard title="Sponsor Co-Invest">
                  {projectFromData?.sponsorCoInvestPercentage}%
                </ProCard>
              </ProCard>

              <ProCard className="text-lg font-semibold bg-[#fafafa]">
                Targeted Returns & Distributions
              </ProCard>
              <ProCard split="vertical">
                <ProCard title="Targeted Investor IRR">
                  {projectFromData?.targetedInvestorIRR}%
                </ProCard>
                <ProCard title="Targeted Equity Multiple">
                  {projectFromData?.targetedEquityMultiple}x
                </ProCard>
              </ProCard>
              <ProCard split="vertical">
                <ProCard title="Total Project IRR">
                  {projectFromData?.totalProjectIRR}%
                </ProCard>
                <ProCard title="Targeted Average Cash Yield">
                  {projectFromData?.targetedAverageCashYield}%
                </ProCard>
              </ProCard>
              <ProCard split="vertical">
                <ProCard title="Targeted Distribution Start Date">
                  {projectFromData?.targetedDistributionStartDate}
                </ProCard>
                <ProCard title="Distribution Period">
                  {projectFromData?.distributionPeriod}
                </ProCard>
              </ProCard>
              <ProCard title="Total Sold">
                {project?.securityToken?.tokenSale &&
                  ethers.utils.commify(
                    ethers.utils.formatUnits(
                      project?.securityToken?.tokenSale?.totalSoldQuantity,
                      project?.securityToken?.decimals
                    )
                  )}
              </ProCard>

              <ProCard className="text-lg font-semibold bg-[#fafafa]">
                Settings
              </ProCard>
              <ProCard split="vertical">
                <ProCard title="Token Name">
                  {projectFromData?.tokenName}
                </ProCard>
                <ProCard title="Token Symbol">
                  {projectFromData?.tokenSymbol}
                </ProCard>
              </ProCard>
              <ProCard title="URL Handle">{projectFromData?.urlHandle}</ProCard>
            </ProCard>
          </RcResizeObserver>
        </Col>
        <Col xl={16} xs={24}>
          <List
            breadcrumb={null}
            title="Shareholders"
            headerProps={{
              extra: <></>,
            }}
          >
            <Table {...shareholderTableProps} rowKey="id">
              <Table.Column dataIndex="name" title="User Name" />
              <Table.Column dataIndex="email" title="Email" />
              <Table.Column dataIndex="kycLevel" title="KYC Level" />
              <Table.Column
                dataIndex="balance"
                title="Balance"
                render={(value) => (
                  <TextField
                    value={numberFormat(
                      Number(ethers.utils.formatUnits(value))
                    )}
                  />
                )}
              />
              <Table.Column
                dataIndex="rate"
                title="Shareholding Rate"
                render={(value) => (
                  <TextField value={`${numberFormat(Number(value) * 100)}%`} />
                )}
              />
            </Table>
          </List>

          <List
            breadcrumb={null}
            title="Distribution Events"
            headerProps={{
              extra: (
                <CreateButton
                  onClick={() => createDistributionShow()}
                  resourceNameOrRouteName={'distributions'}
                />
              ),
            }}
          >
            <Table {...distributionTableProps} rowKey="id">
              <Table.Column
                dataIndex="distributionScheduleAt"
                title="Distribution Schedule At"
                key="distributionScheduleAt"
                render={(value) => <DateField value={value} format="LLL" />}
              />
              <Table.Column title={'Total Payout'} dataIndex="payout" />
              <Table.Column title={'Status'} dataIndex={'status'} />
              <Table.Column
                dataIndex="dividendEligibilityDeadline"
                title="Dividend Eligibility Deadline"
                key="dividendEligibilityDeadline"
                render={(value) => <DateField value={value} format="LLL" />}
              />
              <Table.Column<any>
                title="Actions"
                dataIndex="id"
                render={(value) => (
                  <Space>
                    <EditButton
                      hideText
                      size="small"
                      recordItemId={value}
                      onClick={() => {
                        editDistributionShow(value);
                      }}
                    />
                    <ShowButton
                      hideText
                      size="small"
                      recordItemId={value}
                      onClick={() => {
                        setSelectedDistributionId(value);
                        dividendModalShow();
                      }}
                    />
                  </Space>
                )}
              />
            </Table>
          </List>

          <List
            breadcrumb={null}
            title="Transfer History"
            headerProps={{
              extra: <></>,
            }}
          >
            <Table {...securityTokenTransferEventTableProps} rowKey="id">
              <Table.Column
                dataIndex="transactionHash"
                title="Transaction Hash"
                render={(value) => (
                  <TransactionLink
                    transactionHash={value}
                    chainId={project?.securityToken?.chainId!}
                  />
                )}
              />
              <Table.Column<SecurityTokenTransferEvent>
                dataIndex={'from'}
                title="From"
                render={(value, record) => (
                  <AddressLink
                    address={value}
                    alias={record?.fromWallet?.member?.name}
                    chainId={project?.securityToken?.chainId!}
                  />
                )}
              />
              <Table.Column<SecurityTokenTransferEvent>
                dataIndex={'to'}
                title="To"
                render={(value, record) => (
                  <AddressLink
                    address={value}
                    alias={record?.toWallet?.member?.name}
                    chainId={project?.securityToken?.chainId!}
                  />
                )}
              />
              <Table.Column
                dataIndex="createdAt"
                title="Created At"
                key="createdAt"
                sorter
                defaultSortOrder={getDefaultSortOrder(
                  'createdAt',
                  securityTokenTransferEventSorter
                )}
                render={(value) => <DateField value={value} format="LLL" />}
              />
            </Table>
          </List>
        </Col>
      </Row>
      <Modal {...editModalProps}>
        <Form {...editFormProps} layout="vertical">
          <Button onClick={() => sendTransaction?.()}>Send Transaction</Button>
          {isLoading && <div>Check Wallet</div>}
          {isSuccess && <div>Transaction: {JSON.stringify(data)}</div>}
        </Form>
      </Modal>
      <Modal {...dividendModalProps} width={'60%'}>
        <DividendList distributionId={selectedDistributionId} />
      </Modal>
      <CreateDistribution
        drawerProps={createDistributionDrawerProps}
        formProps={createDistributionFormProps}
        saveButtonProps={createDistributionSaveButtonProps}
        onFinish={onFinishCreateDistribution}
        form={createDistributionForm}
      />
      <EditDistribution
        drawerProps={editDistributionDrawerProps}
        formProps={editDistributionFormProps}
        saveButtonProps={editDistributionSaveButtonProps}
        onFinish={onFinishEditDistribution}
        form={editDistributionForm}
      />
    </Show>
  );
};
