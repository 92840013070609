import {
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Select, Space } from 'antd';
import type { FormInstance } from 'antd/es/form';
import classNames from 'classnames';
import dayjs from 'dayjs';
import CustomFieldLabel from 'views/projects/containers/CustomFieldLabel';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

interface Props {
  form: FormInstance<any>;
  isShow: boolean;
  isDraft?: boolean;
  diff: any;
}

export default function ProjectDetailsFormItems({ form, isShow, diff }: Props) {
  return (
    <div
      className={classNames('hidden', {
        '!block': isShow,
      })}
    >
      <Form.Item
        name="projectName"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.projectName !== undefined,
            })}
          >
            Project Name
          </div>
        }
        // label="Project Name"
        rules={[{ required: isShow }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="projectDescription"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.projectDescription !== undefined,
            })}
          >
            Project Preview Description
          </div>
        }
        // label="Project Preview Description"
        tooltip={{
          icon: <InfoCircleOutlined />,
          title:
            'This information will be used to generate a preview card on the Investments page.',
        }}
        rules={[{ required: isShow }]}
      >
        <TextArea rows={3} />
      </Form.Item>
      <Form.Item
        name="investmentType"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.investmentType !== undefined,
            })}
          >
            Investment Type
          </div>
        }
        // label="Investment Type"
        rules={[{ required: isShow }]}
      >
        <Select
          options={[
            { value: 'equity', label: 'Equity' },
            { value: 'equityFund', label: 'Equity Fund' },
            { value: 'mezzanine', label: 'Mezzanine' },
            { value: 'debt', label: 'Debt' },
            { value: 'portfolio', label: 'Portfolio' },
            {
              value: 'preferredEquity',
              label: 'Preferred Equity',
            },
            { value: 'REIT', label: 'REIT' },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="propertyType"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.propertyType !== undefined,
            })}
          >
            Property Type
          </div>
        }
        // label="Property Type"
        rules={[{ required: isShow }]}
      >
        <Select
          options={[
            { value: 'multifamily', label: 'Multifamily' },
            { value: 'Single Family', label: 'Single Family' },
            { value: 'Multi-Asset', label: 'Multi-Asset' },
            { value: 'Hotel', label: 'Hotel' },
            { value: 'Built-to-Rent', label: 'Built-to-Rent' },
            { value: 'Flex R&D', label: 'Flex R&D' },
            { value: 'Hospitality', label: 'Hospitality' },
            { value: 'Industrial', label: 'Industrial' },
            { value: 'Land', label: 'Land' },
            { value: 'Medical Office', label: 'Medical Office' },
            { value: 'Mixed Use', label: 'Mixed Use' },
            { value: 'Office', label: 'Office' },
            { value: 'Residential', label: 'Residential' },
            { value: 'Retail', label: 'Retail' },
            { value: 'Senior Housing', label: 'Senior Housing' },
            { value: 'Storage', label: 'Storage' },
            { value: 'Student Housing', label: 'Student Housing' },
            {
              value: 'Manufactured Housing',
              label: 'Manufactured Housing',
            },
            { value: 'Flex/Office', label: 'Flex/Office' },
            { value: 'Data Center', label: 'Data Center' },
            { value: 'Parking Garage', label: 'Parking Garage' },
            { value: 'Co-Living', label: 'Co-Living' },
            { value: 'Specialty', label: 'Specialty' },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="cityState"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.cityState !== undefined,
            })}
          >
            City, State | MSA
          </div>
        }
        // label="City, State | MSA"
        tooltip={{
          icon: <InfoCircleOutlined />,
          title:
            "If your project's location spans across multiple states in the US, please enter 'Multiple States' in this field.",
        }}
        rules={[{ required: isShow }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.addresses !== undefined,
            })}
          >
            Address
          </div>
        }
        // label="Address"
      >
        <Form.List name="addresses">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div className="flex items-center gap-4 mb-6" key={key}>
                  <Form.Item
                    className="flex-1 !mb-0"
                    {...restField}
                    name={[name, 'address']}
                  >
                    <Input placeholder="address" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </div>
              ))}
              <Form.Item className="mb-0">
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add address
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
      <Form.Item
        name="investmentStrategy"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.investmentStrategy !== undefined,
            })}
          >
            Investment Strategy
          </div>
        }
        // label="Investment Strategy"
        rules={[{ required: isShow }]}
      >
        <Select
          options={[
            { value: 'core', label: 'Core' },
            { value: 'Core Plus', label: 'Core Plus' },
            { value: 'Value Add', label: 'Value Add' },
            { value: 'Opportunistic', label: 'Opportunistic' },
            { value: 'Development', label: 'Development' },
            { value: 'Custom', label: 'Custom' },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="initialDate"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.initialDate !== undefined,
            })}
          >
            Initial Offering Date
          </div>
        }
        // label="Initial Offering Date"
        tooltip={{
          icon: <InfoCircleOutlined />,
          title:
            "The initial offering date determines when a project is available for purchase. If a project is published before its initial offering date, a countdown timer and 'coming soon' label will be displayed to indicate its status.",
        }}
        rules={[{ required: isShow }]}
        getValueProps={(value) => {
          return {
            value: value ? value.map((_value: string) => dayjs(_value)) : '',
          };
        }}
      >
        <RangePicker showTime />
      </Form.Item>
      <Form.List name="projectDetailsCustomFields">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const label = form.getFieldValue(['customFields', name, 'label']);
              return (
                <Space
                  key={key}
                  style={{ display: 'flex', marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    className="hidden"
                    {...restField}
                    name={[name, 'label']}
                    initialValue="Custom Field"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'field']}
                    label={
                      <>
                        <CustomFieldLabel
                          value={label}
                          formName={name}
                          onSave={(_label) => {
                            form.setFieldValue(
                              ['customFields', name, 'label'],
                              _label
                            );
                          }}
                          remove={remove}
                        />
                      </>
                    }
                    rules={[{ required: true, message: 'Missing first name' }]}
                  >
                    <Input placeholder="value" />
                  </Form.Item>
                </Space>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
}
