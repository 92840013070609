import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import type { FormInstance } from 'antd/es/form';
import classNames from 'classnames';
import CustomFieldLabel from 'views/projects/containers/CustomFieldLabel';

interface Props {
  form: FormInstance<any>;
  isShow: boolean;
  isDraft?: boolean;
  diff: any;
}

export default function TargetedReturnsAndDistributionsFormItems({
  form,
  isShow,
  diff,
}: Props) {
  return (
    <div
      className={classNames('hidden', {
        '!block': isShow,
      })}
    >
      <Form.Item
        name="targetedInvestorIRR"
        // label="Targeted Investor IRR"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.targetedInvestorIRR !== undefined,
            })}
          >
            Targeted Investor IRR
          </div>
        }
        rules={[
          {
            required: isShow,
          },
        ]}
      >
        <InputNumber
          className="w-full"
          formatter={(value) => `${value}%`}
          parser={(value) => value!.replace('%', '')}
        />
      </Form.Item>
      <Form.Item
        name="targetedEquityMultiple"
        // label="Targeted Equity Multiple"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.targetedEquityMultiple !== undefined,
            })}
          >
            Targeted Equity Multiple
          </div>
        }
        rules={[
          {
            required: isShow,
          },
        ]}
      >
        <InputNumber
          className="w-full"
          formatter={(value) => `${value}x`}
          parser={(value) => value!.replace('x', '')}
        />
      </Form.Item>
      <Form.Item
        name="totalProjectIRR"
        // label="Total Project IRR"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.totalProjectIRR !== undefined,
            })}
          >
            Total Project IRR
          </div>
        }
      >
        <InputNumber
          className="w-full"
          formatter={(value) => `${value}%`}
          parser={(value) => value!.replace('%', '')}
        />
      </Form.Item>
      <Form.Item
        name="targetedAverageCashYield"
        // label="Targeted Average Cash Yield"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.targetedAverageCashYield !== undefined,
            })}
          >
            Targeted Average Cash Yield
          </div>
        }
      >
        <InputNumber
          className="w-full"
          formatter={(value) => `${value}%`}
          parser={(value) => value!.replace('%', '')}
        />
      </Form.Item>
      <Form.Item
        name="targetedDistributionStartDate"
        // label="Targeted Distribution Start Date"
        label={
          <div
            className={classNames({
              'bg-purple-100':
                diff?.targetedDistributionStartDate !== undefined,
            })}
          >
            Targeted Distribution Start Date
          </div>
        }
        rules={[
          {
            required: isShow,
          },
        ]}
      >
        <Input placeholder="Q3 of 2024" />
      </Form.Item>
      <Form.Item
        name="distributionPeriod"
        // label="Distribution Period"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.distributionPeriod !== undefined,
            })}
          >
            Distribution Period
          </div>
        }
        rules={[
          {
            required: isShow,
          },
        ]}
      >
        <Select
          options={[
            { value: 'Monthly', label: 'Monthly' },
            { value: 'Quarterly', label: 'Quarterly' },
            { value: 'Semi Annually', label: 'Semi Annually' },
            { value: 'Annually', label: 'Annually' },
          ]}
        />
      </Form.Item>
      <Form.List name="TargetedReturnsAndDistributionsCustomFields">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              const label = form.getFieldValue(['customFields', name, 'label']);
              return (
                <div key={key} className="mb-2">
                  <Form.Item
                    className="hidden"
                    {...restField}
                    name={[name, 'label']}
                    initialValue="Custom Field"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'field']}
                    label={
                      <>
                        <CustomFieldLabel
                          value={label}
                          formName={name}
                          onSave={(_label) => {
                            form.setFieldValue(
                              ['customFields', name, 'label'],
                              _label
                            );
                          }}
                          remove={remove}
                        />
                      </>
                    }
                    rules={[{ required: true, message: 'Missing first name' }]}
                  >
                    <Input className="w-full" placeholder="value" />
                  </Form.Item>
                </div>
              );
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
}
