import { MailOutlined } from '@ant-design/icons';
import { notificationProvider, Show } from '@pankod/refine-antd';
import { useMutation, useQuery } from '@pankod/refine-core';
import type { ResetPasswordBody } from '@tokenbricks/talfa-backend-typescript-axios-client';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import {
  adminAuthenticationApi,
  adminGroupManagementAdminApi,
} from 'api/coreBackend/defHttp';
import { useState } from 'react';

const { Text } = Typography;

export default function Personal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate, isLoading: isLoadingResetPassword } = useMutation(
    (data: ResetPasswordBody) => {
      return adminAuthenticationApi.adminResetPassword(data);
    },
    {
      onSuccess: () => {
        notificationProvider.open({
          type: 'success',
          message: 'Successfully reset your password.',
        });
      },
      onError: (error: { message: string }) => {
        notificationProvider.open({
          type: 'error',
          message: error.message,
        });
      },
    }
  );
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
    form.resetFields();
  };

  const handleOk = async () => {
    // setIsModalOpen(false);
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      mutate({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        newPasswordConfirmation: values.newPasswordConfirmation,
      });
      setIsModalOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { data: userRes, isLoading: isLoadingUser } = useQuery(
    ['adminAuthenticationApi.adminGetMe'],
    () => {
      return adminAuthenticationApi.adminGetMe();
    }
  );
  const user = userRes?.data;

  const { data: groupsRes, isLoading: isLoadingGroup } = useQuery(
    ['adminGroupManagementAdminApi.getManyBaseAdminGroupControllerAdminGroup'],
    () => {
      return adminGroupManagementAdminApi.getManyBaseAdminGroupControllerAdminGroup();
    }
  );

  const groupsData = groupsRes?.data;

  return (
    <Show
      isLoading={isLoadingUser || isLoadingGroup}
      title="Personal Info"
      headerButtons={() => (
        <>
          <Button
            disabled={isLoadingUser || isLoadingGroup}
            loading={isLoadingResetPassword}
            icon={<MailOutlined />}
            onClick={() => {
              showModal();
            }}
            // loading={resendEmailMutation.isLoading}
          >
            Reset Password
          </Button>
        </>
      )}
    >
      <Modal
        title="Reset Password"
        open={isModalOpen}
        onOk={handleOk}
        okText="Save"
        onCancel={handleCancel}
      >
        <Form
          className="mt-6"
          form={form}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            label="Current Password"
            name="currentPassword"
            rules={[
              {
                required: true,
                message: 'Please input your current password!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              { required: true, message: 'Please input your new password!' },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="newPasswordConfirmation"
            rules={[
              {
                required: true,
                message: 'Please input your new password confirmation!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Name: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{user?.name}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Email: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{user?.email}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Role: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{user?.role.name}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Groups: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>
              {(user as any)?.groupMappings.length !== 0
                ? (user as any)?.groupMappings.map((group: any) => (
                    <Tag color="blue">
                      {
                        groupsData?.find((item) => item.id === group.groupId)
                          ?.name
                      }
                    </Tag>
                  ))
                : '--'}
            </Text>
          </Space>
        </Col>
      </Row>
    </Show>
  );
}
