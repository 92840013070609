import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import { useProjectMethod } from 'views/projects/hooks/useProjectMethod';

interface Props {
  projectId: number;
  isPublish: boolean;
}

export default function SetPublishButton({ projectId, isPublish }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { publishProjectMutation, unpublishProjectMutation } =
    useProjectMethod();

  const handleClicked = () => {
    if (isPublish) {
      unpublishProjectMutation.mutate({ projectId });
    } else {
      publishProjectMutation.mutate({ projectId });
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => {
          setIsModalOpen(true);
        }}
        loading={
          unpublishProjectMutation.isLoading || publishProjectMutation.isLoading
        }
      >
        {isPublish ? 'Unpublish' : 'Publish'}
      </Button>
      <Modal
        title={
          <div className="flex gap-4 items-center">
            <InfoCircleOutlined
              style={{ fontSize: '22px', color: '#D90C34' }}
              color="#D90C34"
            />
            <div>{isPublish ? 'Unpublish Project' : 'Publish Project'}</div>
          </div>
        }
        open={isModalOpen}
        onOk={handleClicked}
        okText="Done"
        onCancel={() => {
          setIsModalOpen(false);
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div className="pl-10">
          <p>
            {isPublish
              ? 'The project will be removed from the Talfa website.'
              : 'The project will be published on the Talfa website.'}
          </p>
        </div>
      </Modal>
    </>
  );
}
