import type {
  AdminProjectAo,
  CreateProjectBodyDto,
} from '@tokenbricks/talfa-backend-typescript-axios-client';
import dayjs from 'dayjs';
import { ethers } from 'ethers';
import {
  numberFormat,
  parseLocale$Number,
  parseLocaleNumberPercentage,
} from 'utils/numberFormat';

import type { ProjectCMSContent, ProjectForm } from '../model/project';

const decimals = 18;

export const convertProjectFormToProjectApiBodyDto: (
  data: ProjectForm
) => CreateProjectBodyDto = (data) => {
  return {
    project: {
      handle: data.urlHandle,
      price: String(data.pricePerToken),
      minimumPurchaseAmount: String(data.minimumInvestment),
    },
    tokenSale: {
      initialOfferingLockSecond: data.minimumHoldingPeriod * 60 * 60 * 24,
      // totalSaleSupply: String(data.totalTokens),
      totalSaleSupply: ethers.utils
        .parseUnits(String(data.totalTokens), decimals)
        .toString(),
      saleStartedAt: dayjs(data.initialDate[0]).toISOString(),
      saleEndedAt: dayjs(data.initialDate[1]).toISOString(),
    },
    securityToken: {
      name: data.tokenName,
      symbol: data.tokenSymbol,
      // totalSupply: String(data.totalTokens),
      totalSupply: ethers.utils
        .parseUnits(String(data.totalTokens), decimals)
        .toString(),
      // maxSupply: String(data.totalTokens),
      maxSupply: ethers.utils
        .parseUnits(String(data.totalTokens), decimals)
        .toString(),
    },
    projectDocument: {
      templateId: '303005000000249019',
    },
    content: {
      name: data.projectName,
      images: data.projectImages,
      previewImage: data.previewImage,
      urlHandle: data.urlHandle,
      document: data.documents,
      summary: data.projectDescription,
      contents: data.contents,
      projectDetails: [
        {
          name: 'Investment Type',
          value: data.investmentType,
        },
        {
          name: 'Property Type',
          value: data.propertyType,
        },
        {
          name: 'City, State | MSA',
          value: data.cityState,
        },
        {
          name: 'Address',
          value:
            data?.addresses?.map((item) => item.address).join('\n\n') ?? '--',
        },
        {
          name: 'Investment Strategy',
          value: data.investmentStrategy,
        },
        {
          name: 'Initial Offering Date',
          value: data.initialDate[0].toString(),
        },
        {
          name: 'Offering Closing Date',
          value: data.initialDate[1].toString(),
        },
      ],
      saleDetails: [
        {
          name: 'Total Fundraising',
          value: `$${numberFormat(data.totalFundraising)}`,
        },
        {
          name: 'Price Per Token',
          value: `$${numberFormat(data.pricePerToken)}`,
        },
        {
          name: 'Total Tokens',
          value: numberFormat(data.totalTokens),
        },
        {
          name: 'Minimum Investment',
          value: `$${numberFormat(data.minimumInvestment)}`,
        },
        {
          name: 'Minimum Holding Period',
          value: `${data.minimumHoldingPeriod} ${
            data.minimumHoldingPeriod === 1 ? 'day' : 'days'
          }`,
        },
        {
          name: 'Sponsor Investment Period',
          value: String(data.sponsorInvestmentPeriod),
        },
        {
          name: 'Sponsor Co-Invest ($)',
          // value: data.sponsorCoInvest$,
          value: `$${numberFormat(data.sponsorCoInvest$)}`,
        },
        {
          name: 'Sponsor Co-Invest (%)',
          // value: data.sponsorCoInvestPercentage,
          value: `${data.sponsorCoInvestPercentage}%`,
        },
      ],
      targetedReturnsAndDistributions: [
        {
          name: 'Targeted Investor IRR',
          // value: data.targetedInvestorIRR,
          value: `${data.targetedInvestorIRR}%`,
        },
        {
          name: 'Targeted Equity Multiple',
          // value: data.targetedEquityMultiple,
          value: `${data.targetedEquityMultiple}x`,
        },
        {
          name: 'Targeted Project IRR',
          // value: data.targetedInvestorIRR,
          value: `${data.targetedInvestorIRR}%`,
        },
        {
          name: 'Targeted Average Cash Yield',
          // value: data.targetedAverageCashYield,
          value: `${data.targetedAverageCashYield}%`,
        },
        {
          name: 'Targeted Distribution Start Date',
          value: data.targetedDistributionStartDate,
        },
        {
          name: 'Distribution Period',
          value: data.distributionPeriod,
        },
      ],
      documents: data.documents,
    },
  };
};

export const convertProjectApiBodyDtoToProjectForm: (
  data: AdminProjectAo
) => ProjectForm = (data) => {
  return {
    decimals,
    investmentType:
      data.content?.data.attributes.projectDetails?.[0]?.value ?? '',
    propertyType:
      data.content?.data.attributes.projectDetails?.[1]?.value ?? '',
    cityState: data.content?.data.attributes.projectDetails?.[2]?.value ?? '',
    addresses:
      data.content?.data.attributes.projectDetails?.[3]?.value
        .split('\n\n')
        .map((item) => ({ address: item })) ?? [],

    investmentStrategy:
      data.content?.data.attributes.projectDetails?.[4]?.value ?? '',
    // initialDate: [data.tokenSale.saleStartedAt, data.tokenSale.saleEndedAt],
    initialDate: [
      data.securityToken?.tokenSale?.saleStartedAt ?? '',
      data.securityToken?.tokenSale?.saleEndedAt ?? '',
    ],

    totalFundraising: parseLocale$Number(
      data.content?.data.attributes.saleDetails?.[0]?.value ?? '$0'
    ),

    pricePerToken: Number(data.price),
    // totalTokens: Number(data.securityToken?.totalSupply),
    totalTokens: Number(
      ethers.utils.formatUnits(
        String(data.securityToken?.totalSupply),
        decimals
      )
    ),

    minimumInvestment: Number(data.minimumPurchaseAmount),
    minimumHoldingPeriod:
      Number(data.securityToken?.tokenSale?.initialOfferingLockSecond) /
      60 /
      60 /
      24,
    sponsorInvestmentPeriod:
      data.content?.data.attributes.saleDetails?.[5]?.value ?? '',
    sponsorCoInvest$: parseLocale$Number(
      data.content?.data.attributes.saleDetails?.[6]?.value ?? '$0'
    ),
    sponsorCoInvestPercentage: parseLocaleNumberPercentage(
      data.content?.data.attributes.saleDetails?.[7]?.value ?? '0%'
    ),

    targetedInvestorIRR: parseLocaleNumberPercentage(
      data.content?.data.attributes.targetedReturnsAndDistributions?.[0]
        ?.value ?? '0%'
    ),

    targetedEquityMultiple:
      Number(
        data.content?.data.attributes.targetedReturnsAndDistributions?.[1]?.value.replace(
          'x',
          ''
        )
      ) ?? 0,
    totalProjectIRR: parseLocaleNumberPercentage(
      data.content?.data.attributes.targetedReturnsAndDistributions?.[2]
        ?.value ?? '0%'
    ),
    targetedAverageCashYield: parseLocaleNumberPercentage(
      data.content?.data.attributes.targetedReturnsAndDistributions?.[3]
        ?.value ?? '0%'
    ),
    targetedDistributionStartDate:
      data.content?.data.attributes.targetedReturnsAndDistributions?.[4]
        ?.value ?? '',
    distributionPeriod:
      data.content?.data.attributes.targetedReturnsAndDistributions?.[5]
        ?.value ?? '',

    projectName: data.content?.data.attributes.name ?? '',
    projectDescription: data.content?.data.attributes.summary ?? '',
    previewImage: data.content?.data.attributes.previewImage,
    projectImages: data.content?.data.attributes.images ?? [],
    documents: data.content?.data.attributes.documents ?? [],
    contents: data.content?.data.attributes.contents ?? [],

    urlHandle: data.handle ?? '',
    tokenName: data.securityToken?.name ?? '',
    tokenSymbol: data.securityToken?.symbol ?? '',
  };
};

export const convertProjectApiCreateBodyDtoToProjectForm: (
  data: CreateProjectBodyDto & { content: ProjectCMSContent }
) => ProjectForm = (data) => {
  return {
    decimals,

    investmentType: data.content?.projectDetails?.[0]?.value ?? '',
    propertyType: data.content?.projectDetails?.[1]?.value ?? '',
    cityState: data.content?.projectDetails?.[2]?.value,
    addresses: data.content?.projectDetails?.[3]?.value
      .split('\n\n')
      .map((item) => ({ address: item })),

    investmentStrategy: data.content?.projectDetails?.[4]?.value,
    // initialDate: [data.tokenSale.saleStartedAt, data.tokenSale.saleEndedAt],
    initialDate: [
      data.tokenSale.saleStartedAt ?? '',
      data.tokenSale.saleEndedAt ?? '',
    ],

    totalFundraising: parseLocale$Number(
      data.content?.saleDetails?.[0]?.value ?? '$0'
    ),
    pricePerToken: Number(data.project.price),
    // totalTokens: Number(data.securityToken.totalSupply),
    totalTokens: Number(
      ethers.utils.formatUnits(
        String(data.securityToken?.totalSupply),
        decimals
      )
    ),
    minimumInvestment: Number(data.project.minimumPurchaseAmount),
    minimumHoldingPeriod:
      Number(data?.tokenSale?.initialOfferingLockSecond) / 60 / 60 / 24,
    sponsorInvestmentPeriod: data.content?.saleDetails?.[5]?.value,
    sponsorCoInvest$: parseLocale$Number(data.content?.saleDetails?.[6]?.value),
    sponsorCoInvestPercentage: parseLocaleNumberPercentage(
      data.content?.saleDetails?.[7]?.value
    ),

    targetedInvestorIRR: parseLocaleNumberPercentage(
      data.content?.targetedReturnsAndDistributions?.[0]?.value
    ),
    targetedEquityMultiple: Number(
      data.content?.targetedReturnsAndDistributions?.[1]?.value.replace('x', '')
    ),
    totalProjectIRR: parseLocaleNumberPercentage(
      data.content?.targetedReturnsAndDistributions[2]?.value
    ),
    targetedAverageCashYield: parseLocaleNumberPercentage(
      data.content?.targetedReturnsAndDistributions?.[3]?.value
    ),
    targetedDistributionStartDate:
      data.content?.targetedReturnsAndDistributions?.[4]?.value,
    distributionPeriod:
      data.content?.targetedReturnsAndDistributions?.[5]?.value,

    projectName: data.content?.name,
    projectDescription: data.content?.summary,
    previewImage: data.content?.previewImage,
    projectImages: data.content?.images,
    documents: data.content?.documents,
    contents: data.content?.contents,

    urlHandle: data.project.handle,
    tokenName: data.securityToken?.name ?? '',
    tokenSymbol: data.securityToken?.symbol ?? '',
  };
};
