export const projectSteps = [
  {
    title: 'Project Details',
    description: <div className="h-4" />,
  },
  {
    title: 'Sale Details',
    description: <div className="h-4" />,
  },
  {
    title: 'Targeted Returns & Distributions',
    description: <div className="h-4" />,
  },
  {
    title: 'Full Descriptions',
    description: <div className="h-4" />,
  },
  {
    title: 'Images & Documents',
    description: <div className="h-4" />,
  },
  {
    title: 'Settings',
    description: <div className="h-4" />,
  },
];
