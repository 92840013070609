import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import { useProjectMethod } from 'views/projects/hooks/useProjectMethod';

interface Props {
  projectId: number;
  projectApplicationId: number;
  disabled?: boolean;
}

export default function ApproveAndPublishButton({
  projectId,
  projectApplicationId,
  disabled = false,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { approveProjectMutation } = useProjectMethod();
  return (
    <>
      <Button
        type="primary"
        disabled={disabled}
        onClick={() => {
          setIsModalOpen(true);
        }}
        loading={approveProjectMutation.isLoading}
      >
        Approve & Publish
      </Button>
      <Modal
        title={
          <div className="flex gap-4 items-center">
            <InfoCircleOutlined
              style={{ fontSize: '22px', color: '#D90C34' }}
              color="#D90C34"
            />
            <div>Submit for Approval</div>
          </div>
        }
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
          approveProjectMutation.mutate({
            projectId,
            projectApplicationId,
            message: '',
          });
        }}
        okText="Submit"
        okButtonProps={{ loading: approveProjectMutation.isLoading }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="pl-10">
          <p>
            Upon approval, this project will be published on the Talfa website
            and made visible to the public. The sponsor will be notified of the
            status update via email.
          </p>
        </div>
      </Modal>
    </>
  );
}
