/* eslint-disable no-underscore-dangle */

// const getImageUrl = (img: ImageAttributes) => {
//   return `${img.url}`;
// };

const imageFormatReg =
  /^image\/(png|webp|jpe?g|gif|bmp|psd|tiff|tga|eps|svg|svg\+xml)$/;

export const convertCmsImageData = (inputObject: any) => {
  if (typeof inputObject !== 'object' || inputObject === null) {
    return inputObject;
  }

  const outputObject: any = Array.isArray(inputObject) ? [] : {};

  (Object.keys(inputObject) as Array<keyof typeof inputObject>).forEach(
    (key) => {
      const value = inputObject[key];
      if (imageFormatReg.test(value?.data?.attributes?.mime)) {
        outputObject[key] = { ...value?.data?.attributes, id: value?.data?.id };
        return;
      }

      if (imageFormatReg.test(value?.data?.[0]?.attributes?.mime)) {
        outputObject[key] = value?.data.map((imageData: any) => ({
          ...imageData?.attributes,
          id: imageData?.id,
        }));
        return;
      }
      outputObject[key] = convertCmsImageData(value);
    }
  );

  return outputObject;
};
