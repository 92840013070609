import { Col, Row, Show, Space, Typography } from '@pankod/refine-antd';
import type { IResourceComponentsProps } from '@pankod/refine-core';
import { useShow } from '@pankod/refine-core';
import type { Order } from '@tokenbricks/talfa-backend-typescript-axios-client';
import dayjs from 'dayjs';

const { Text } = Typography;

export const OrderShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<Order>();
  return (
    <Show isLoading={queryResult.isLoading} title="Transition Detail">
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>User Name: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult.data?.data.member.name}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>User Email: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult.data?.data.member.email}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Payment Type: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult.data?.data.paymentType}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Price: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>$ {queryResult.data?.data.price}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>amount: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult.data?.data.amount}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Status: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult.data?.data.status}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Order Code: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult.data?.data.orderCode}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Project Id: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult.data?.data.projectId}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Paid At: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{dayjs(queryResult.data?.data.paidAt).format('LLL')}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Created At: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{dayjs(queryResult.data?.data.createdAt).format('LLL')}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Updated At: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{dayjs(queryResult.data?.data.updatedAt).format('LLL')}</Text>
          </Space>
        </Col>
      </Row>
    </Show>
  );
};
