import type { HttpError } from '@pankod/refine-core';
import {
  useMutation,
  useQueryClient,
  useRouterContext,
} from '@pankod/refine-core';
import type { CreateAndSubmitProjectApplicationBodyDto } from '@tokenbricks/talfa-backend-typescript-axios-client';
import { notification } from 'antd';
import { projectManagementAdminApi } from 'api/coreBackend/defHttp';
import { mediaUploadMapper } from 'views/content/utils/mediaUploadMapper';

import type { ProjectForm } from '../model/project';
import { convertProjectFormToProjectApiBodyDto } from '../utils/projects';

export const useProjectMethod = () => {
  const { useHistory } = useRouterContext();
  const history = useHistory();
  const queryClient = useQueryClient();

  const createProjectMutation = useMutation(
    (data: ProjectForm) => {
      return projectManagementAdminApi.createOneBaseAdminProjectControllerProject(
        convertProjectFormToProjectApiBodyDto(mediaUploadMapper(data))
      );
    },
    {
      onSuccess: (data) => {
        notification.success({
          message: 'Success',
          description: 'Project created successfully!',
        });
        history.push(`/projects/edit/${data.data.id}`);
      },
      onError: (e: HttpError & { error: HttpError }) => {
        notification.error({
          message: 'Error',
          description: e?.error?.message || e?.message,
        });
      },
    }
  );

  const updateDraftProjectMutation = useMutation(
    ({ id, data }: { id: number; data: ProjectForm }) => {
      return projectManagementAdminApi.updateOneBaseAdminProjectControllerProject(
        id,
        convertProjectFormToProjectApiBodyDto(mediaUploadMapper(data))
      );
    },
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getOneBaseAdminProjectControllerProject',
        ]);
        notification.success({
          message: 'Success',
          description: 'Project updated successfully!',
        });
      },
      onError: (e: HttpError & { error: HttpError }) => {
        notification.error({
          message: 'Error',
          description: e?.error?.message || e?.message,
        });
      },
    }
  );

  const submitDraftProjectMutation = useMutation(
    (
      data: CreateAndSubmitProjectApplicationBodyDto & {
        createData: { projectId: number; data: ProjectForm };
      }
    ) => {
      return projectManagementAdminApi.createAndSubmitProjectApplication({
        ...data,
        createData: {
          ...data.createData,
          data: convertProjectFormToProjectApiBodyDto(
            mediaUploadMapper(data.createData.data)
          ),
        },
      });
    },
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getOneBaseAdminProjectControllerProject',
        ]);
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getManyBaseAdminProjectApplicationControllerProjectApplication',
        ]);
        notification.success({
          message: 'Submission Success!',
          description:
            'Thank you for submitting your project. You will receive an email once the admin has reviewed your submission.',
        });
      },
      onError: (e: HttpError & { error: HttpError }) => {
        notification.error({
          message: 'Error',
          description: e?.error?.message || e?.message,
        });
      },
    }
  );

  const submitPublishedProjectMutation = useMutation(
    ({
      projectApplicationId,
      message,
    }: {
      projectApplicationId: number;
      message: string;
    }) => {
      return projectManagementAdminApi.submitProjectApplication(
        projectApplicationId,
        {
          submitMessage: message,
        }
      );
    },
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getOneBaseAdminProjectControllerProject',
        ]);
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getManyBaseAdminProjectApplicationControllerProjectApplication',
        ]);
        notification.success({
          message: 'Submission Success!',
          description:
            'Thank you for submitting your project. You will receive an email once the admin has reviewed your submission.',
        });
      },
      onError: (e: HttpError & { error: HttpError }) => {
        notification.error({
          message: 'Error',
          description: e?.error?.message || e?.message,
        });
      },
    }
  );

  const approveProjectMutation = useMutation(
    ({
      projectId,
      projectApplicationId,
      message,
    }: {
      projectId: number;
      projectApplicationId: number;
      message: string;
    }) => {
      return projectManagementAdminApi.approveProjectApplication(
        projectApplicationId,
        {
          reviewMessage: message,
          callbackPath: `/projects/edit/${projectId}`,
        }
      );
    },
    {
      onSuccess: (_) => {
        notification.success({
          message: 'Publish Success!',
          description: 'This project has been published on the Talfa Website.',
        });
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getOneBaseAdminProjectControllerProject',
        ]);
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getManyBaseAdminProjectApplicationControllerProjectApplication',
        ]);
      },
      onError: (e: HttpError & { error: HttpError }) => {
        notification.error({
          message: 'Error',
          description: e?.error?.message || e?.message,
        });
      },
    }
  );

  const rejectProjectMutation = useMutation(
    ({
      projectId,
      projectApplicationId,
      message,
    }: {
      projectId: number;
      projectApplicationId: number;
      message: string;
    }) => {
      return projectManagementAdminApi.rejectProjectApplication(
        projectApplicationId,
        {
          reviewMessage: message,
          callbackPath: `/projects/edit/${projectId}`,
        }
      );
    },
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getOneBaseAdminProjectControllerProject',
        ]);
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getManyBaseAdminProjectApplicationControllerProjectApplication',
        ]);
        notification.success({
          message: 'Message sent successfully!',
          description: 'Reply to rejection message',
        });
      },
      onError: (e: HttpError & { error: HttpError }) => {
        notification.error({
          message: 'Error',
          description: e?.error?.message || e?.message,
        });
      },
    }
  );

  const updatePublishedProjectMutation = useMutation(
    ({
      projectApplicationId,
      data,
    }: {
      projectApplicationId: number;
      data: ProjectForm;
    }) => {
      return projectManagementAdminApi.updateOneBaseAdminProjectApplicationControllerProjectApplication(
        projectApplicationId,
        { data: convertProjectFormToProjectApiBodyDto(mediaUploadMapper(data)) }
      );
    },
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getOneBaseAdminProjectApplicationControllerProjectApplication',
        ]);
        notification.success({
          message: 'Success',
          description: 'Project updated successfully!',
        });
      },
      onError: (e: HttpError & { error: HttpError }) => {
        notification.error({
          message: 'Error',
          description: e?.error?.message || e?.message,
        });
      },
    }
  );

  const createProjectApplicationMutation = useMutation(
    ({ projectId, data }: { projectId: number; data: ProjectForm }) => {
      return projectManagementAdminApi.createOneBaseAdminProjectApplicationControllerProjectApplication(
        {
          projectId,
          data: convertProjectFormToProjectApiBodyDto(mediaUploadMapper(data)),
        }
      );
    },
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getOneBaseAdminProjectControllerProject',
        ]);
        notification.success({
          message: 'Success',
          description: 'Project updated successfully!',
        });
      },
      onError: (e: HttpError & { error: HttpError }) => {
        notification.error({
          message: 'Error',
          description: e?.error?.message || e?.message,
        });
      },
    }
  );

  const updatePublishedProject = ({
    firstUpdate,
    id,
    data,
  }: {
    firstUpdate: boolean;
    id: number;
    data: ProjectForm;
  }) => {
    if (firstUpdate) {
      createProjectApplicationMutation.mutate({ projectId: id, data });
    } else {
      updatePublishedProjectMutation.mutate({ projectApplicationId: id, data });
    }
  };

  const publishProjectMutation = useMutation(
    ({ projectId }: { projectId: number }) => {
      return projectManagementAdminApi.publishProject(projectId);
    },
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getOneBaseAdminProjectControllerProject',
        ]);
        notification.success({
          message: 'Publish Success!',
          description: 'This project has been published on the Talfa Website.',
        });
      },
      onError: (e: HttpError & { error: HttpError }) => {
        notification.error({
          message: 'Error',
          description: e?.error?.message || e?.message,
        });
      },
    }
  );

  const unpublishProjectMutation = useMutation(
    ({ projectId }: { projectId: number }) => {
      return projectManagementAdminApi.unpublishProject(projectId);
    },
    {
      onSuccess: (_) => {
        queryClient.invalidateQueries([
          'projectManagementAdminApi.getOneBaseAdminProjectControllerProject',
        ]);
        notification.success({
          message: 'Unpublish Success!',
          description:
            'This project has been unpublished on the Talfa Website.',
        });
      },
      onError: (e: HttpError & { error: HttpError }) => {
        notification.error({
          message: 'Error',
          description: e?.error?.message || e?.message,
        });
      },
    }
  );

  return {
    createProjectMutation,
    updateDraftProjectMutation,
    submitDraftProjectMutation,
    submitPublishedProjectMutation,
    approveProjectMutation,
    rejectProjectMutation,
    publishProjectMutation,
    unpublishProjectMutation,
    updatePublishedProjectMutation: {
      mutate: updatePublishedProject,
      isLoading:
        updatePublishedProjectMutation.isLoading ||
        createProjectApplicationMutation.isLoading,
    },
  };
};
