/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */

export function compareJSON(newJSON: any, oldJSON: any): any {
  const diff: any = {};

  // 遍历新的 JSON 对象的键
  for (const key in newJSON) {
    if (newJSON?.hasOwnProperty(key)) {
      // 检查键是否存在于旧的 JSON 对象中
      if (!oldJSON?.hasOwnProperty(key)) {
        diff[key] = newJSON[key]; // 记录新的 JSON 中的键值对
      } else if (
        typeof newJSON[key] === 'object' &&
        !Array.isArray(newJSON[key])
      ) {
        // 如果值是对象，则递归比较
        const nestedDiff = compareJSON(newJSON[key], oldJSON[key]);
        if (Object.keys(nestedDiff).length > 0) {
          diff[key] = nestedDiff;
        }
      } else if (Array.isArray(newJSON[key]) && Array.isArray(oldJSON[key])) {
        // 如果值是数组，则逐个比较数组中的元素
        const arrayDiff = compareArray(newJSON[key], oldJSON[key]);
        if (arrayDiff.length > 0) {
          diff[key] = arrayDiff;
        }
      } else if (newJSON[key] !== oldJSON[key]) {
        diff[key] = newJSON[key]; // 记录值不同的键值对
      }
    }
  }

  return diff;
}

function compareArray(newArray: any[], oldArray: any[]): any[] {
  if (newArray.length !== oldArray.length) {
    return newArray; // 如果数组长度不同，则直接返回新数组
  }
  const diff: any[] = [];

  // 比较数组中的元素
  for (let i = 0; i < newArray.length; i += 1) {
    if (typeof newArray[i] === 'object' && !Array.isArray(newArray[i])) {
      // 如果元素是对象，则递归比较
      const nestedDiff = compareJSON(newArray[i], oldArray[i]);
      if (Object.keys(nestedDiff).length > 0) {
        diff.push(nestedDiff);
      }
    } else if (newArray[i] !== oldArray[i]) {
      diff.push(newArray[i]); // 记录不同的元素
    }
  }

  return diff;
}

// interface DiffObject {
//   [key: string]: any;
// }

// export function compareJSON(json1: any, json2: any): DiffObject {
//   function compareObjects(obj1: any, obj2: any, path: string = ''): DiffObject {
//     const diff: DiffObject = {};
//     let activeObject = obj1;
//     if (obj1 && obj2 && typeof obj1 === 'object' && typeof obj2 === 'object') {
//       if (Object.keys(obj1).length < Object.keys(obj2).length) {
//         activeObject = obj2;
//       }
//     }

//     // console.log('activeObject', activeObject);

//     // eslint-disable-next-line no-restricted-syntax
//     for (const key in activeObject) {
//       // eslint-disable-next-line no-prototype-builtins
//       if (obj1.hasOwnProperty(key)) {
//         if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
//           const nestedDiff = compareObjects(
//             obj1[key],
//             obj2[key],
//             `${path}.${key}`
//           );
//           if (Object.keys(nestedDiff).length > 0) {
//             diff[key] = nestedDiff;
//           }
//         } else if (obj1[key] !== obj2[key]) {
//           diff[key] = obj1[key];
//         }
//       }
//     }

//     return diff;
//   }

//   return compareObjects(json1, json2);
// }

// export function compareJSON(json1: any, json2: any): DiffObject {
//   function compareValues(val1: any, val2: any): any {
//     if (Array.isArray(val1) && Array.isArray(val2)) {
//       if (!val1.every((v, i) => deepEqual(v, val2[i]))) {
//         return val1;
//       }
//     } else if (typeof val1 === 'object' && typeof val2 === 'object') {
//       if (!deepEqual(val1, val2)) {
//         return val1;
//       }
//     } else if (val1 !== val2) {
//       return val1;
//     }

//     return null;
//   }

//   function compareObjects(obj1: any, obj2: any): DiffObject {
//     const diff: DiffObject = {};

//     for (const key in obj1) {
//       if (obj1.hasOwnProperty(key)) {
//         if (obj2.hasOwnProperty(key)) {
//           const valueDiff = compareValues(obj1[key], obj2[key]);
//           if (valueDiff !== null) {
//             diff[key] = valueDiff;
//           }
//         } else {
//           diff[key] = obj1[key];
//         }
//       }
//     }

//     for (const key in obj2) {
//       if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
//         diff[key] = obj1[key];
//       }
//     }

//     return diff;
//   }

//   function deepEqual(val1: any, val2: any): boolean {
//     if (typeof val1 !== typeof val2) {
//       return false;
//     }

//     if (typeof val1 !== 'object') {
//       return val1 === val2;
//     }

//     if (Array.isArray(val1) && Array.isArray(val2)) {
//       if (val1.length !== val2.length) {
//         return false;
//       }

//       for (let i = 0; i < val1.length; i += 1) {
//         if (!deepEqual(val1[i], val2[i])) {
//           return false;
//         }
//       }
//     } else {
//       const keys1 = Object.keys(val1);
//       const keys2 = Object.keys(val2);

//       if (keys1.length !== keys2.length) {
//         return false;
//       }

//       for (const key of keys1) {
//         if (!val2.hasOwnProperty(key) || !deepEqual(val1[key], val2[key])) {
//           return false;
//         }
//       }
//     }

//     return true;
//   }

//   return compareObjects(json1, json2);
// }
