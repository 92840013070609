import { ProjectStep } from '../enum/steps';

export const formStepsFields = {
  [ProjectStep.PROJECT_DETAILS]: [
    'projectName',
    'projectDescription',
    'investmentType',
    'propertyType',
    'cityState',
    'investmentStrategy',
    'initialDate',
  ],
  [ProjectStep.SALE_DETAILS]: [
    'totalFundraising',
    'pricePerToken',
    'totalTokens',
    'minimumInvestment',
    'minimumHoldingPeriod',
    'sponsorInvestmentPeriod',
  ],
  [ProjectStep.TARGETED_RETURNS_AND_DISTRIBUTIONS]: [
    'targetedInvestorIRR',
    'targetedEquityMultiple',
    'targetedDistributionStartDate',
    'distributionPeriod',
  ],
  [ProjectStep.FULL_DESCRIPTIONS]: [],
  [ProjectStep.IMAGES_AND_DOCUMENTS]: [
    'previewImage',
    'projectImages',
    'documents',
  ],
  [ProjectStep.SETTINGS]: ['urlHandle', 'tokenName', 'tokenSymbol'],
};
