import {
  Button,
  Col,
  Create,
  EditButton,
  Form,
  Input,
  ListButton,
  Row,
  SaveButton,
  Select,
  Steps,
  useSelect,
  useStepsForm,
} from '@pankod/refine-antd';
import { useCreate, useUpdate } from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import type { Chain } from '@tokenbricks/talfa-backend-typescript-axios-client';
import { projectManagementAdminApi } from 'api/coreBackend/defHttp';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

export const ProjectSetting: React.FC = () => {
  const { id } = useParams();
  console.log('id:', id);
  const { current, stepsProps, formProps, gotoStep, formLoading } =
    useStepsForm();

  const {
    selectProps: supportedChainsProps,
    queryResult: { isLoading: isSelectLoading },
  } = useSelect<Chain>({
    dataProviderName: 'customAPI',
    resource: 'document-templates',
    optionValue: 'template_id',
    optionLabel: 'template_name',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [formValueMap, setFormValueMap] = useState<any>({});

  const { mutate: callUpdate } = useUpdate();
  const { mutate: callCreate } = useCreate();

  const handleProjectDocumentSubmit = async () => {
    if (formValueMap[0]?.id) {
      callUpdate({
        dataProviderName: 'crud',
        resource: 'project-documents',
        id: formValueMap[0].id,
        values: {
          templateId: formProps.form?.getFieldValue('templateId'),
          approverEmail: formProps.form?.getFieldValue('approverEmail'),
        },
      });
    } else {
      callCreate({
        dataProviderName: 'crud',
        resource: 'project-documents',
        values: {
          templateId: formProps.form?.getFieldValue('templateId'),
          approverEmail: formProps.form?.getFieldValue('approverEmail'),
        },
      });
    }
  };

  const handleSubmitMap: Record<number, () => any> = {
    0: handleProjectDocumentSubmit,
    1: () => {
      console.log('submit token');
    },
    2: () => {
      console.log('submit token sale');
    },
  };

  useEffect(() => {
    async function updateInitialValues(projectId: any) {
      const { data } =
        await projectManagementAdminApi.getOneBaseAdminProjectControllerProject(
          projectId
        );

      setFormValueMap({ ...formValueMap, ...{ 0: data?.projectDocument } });

      formProps.form?.setFieldValue(
        'templateId',
        data?.projectDocument?.templateId
      );
      // formProps.form?.setFieldValue(
      //   'approverEmail',
      //   data.
      // );

      setIsLoading(false);
    }

    updateInitialValues(id!);
  }, []);

  const formList = [
    <>
      <Row gutter={20}>
        <Col xs={24} lg={16}>
          <Row gutter={10}>
            <Col xs={24} lg={12}>
              <Form.Item
                label="Document"
                name="templateId"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select {...supportedChainsProps} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Approver email"
            name="approverEmail"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </>,

    <Row key="relations" gutter={20}>
      <Col xs={24} lg={12}>
        <Form.Item
          label={t('couriers.fields.accountNumber')}
          name="accountNumber"
          rules={[
            {
              required: true,
              len: 10,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} lg={12}>
        <Form.Item
          label={t('couriers.fields.vehicle')}
          name="licensePlate"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>,

    <Row key="relations" gutter={20}>
      <Col xs={24} lg={12}>
        <Form.Item
          label={t('couriers.fields.accountNumber')}
          name="accountNumber"
          rules={[
            {
              required: true,
              len: 10,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} lg={12}>
        <Form.Item
          label={t('couriers.fields.vehicle')}
          name="licensePlate"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>,
  ];

  return (
    <Create
      isLoading={formLoading || isSelectLoading || isLoading}
      headerButtons={() => (
        <>
          <ListButton />
          <EditButton />
        </>
      )}
      title="Project Detail"
      footerButtons={
        <>
          {current > 0 && (
            <Button
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              Previous
            </Button>
          )}
          {current < formList.length - 1 && (
            <Button
              onClick={() => {
                gotoStep(current + 1);
              }}
              disabled={!formValueMap[current]}
            >
              Next
            </Button>
          )}
          {<SaveButton onClick={handleSubmitMap[current]} />}
          {/* {current === formList.length - 1 && <SaveButton onClick={handleSubmit}/>} */}
        </>
      }
    >
      <div className="p-0">
        <Steps
          {...stepsProps}
          responsive
          items={[
            { title: 'Set the Document' },
            { title: 'Deploy the Token' },
            { title: 'Set the Token Sale' },
          ]}
        />
        <Form {...formProps} style={{ marginTop: 30 }} layout="vertical">
          {formList[current]}
        </Form>
      </div>
    </Create>
  );
};
