import {
  Col,
  Row,
  Show,
  Space,
  Typography,
  useTable,
} from '@pankod/refine-antd';
import type {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import type { MemberDocument } from '@tokenbricks/talfa-backend-typescript-axios-client';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

const { Text } = Typography;

export const DocumentShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();
  const { tableProps } = useTable<
    MemberDocument,
    HttpError,
    {
      keyword: string;
      status: string[];
      createdAt: [Dayjs, Dayjs];
    }
  >({
    dataProviderName: 'crud',
    resource: 'member-documents',
    initialSorter: [
      {
        field: 'updatedAt',
        order: 'desc',
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { keyword, createdAt, status } = params;
      filters.push(
        {
          operator: 'or',
          value: [
            {
              field: 'member.email',
              operator: 'contains',
              value: keyword || undefined,
            },
            {
              field: 'member.name',
              operator: 'contains',
              value: keyword || undefined,
            },
          ],
        },
        {
          field: 'status',
          operator: 'in',
          value: status && status.length > 0 ? status : undefined,
        },
        {
          field: 'createdAt',
          operator: 'gte',
          value: createdAt ? createdAt[0].toISOString() : undefined,
        },
        {
          field: 'createdAt',
          operator: 'lte',
          value: createdAt ? createdAt[1].toISOString() : undefined,
        }
      );

      return filters;
    },
  });
  const queryResult = tableProps.dataSource?.filter(
    (data) => data.id === Number(id)
  )?.[0] as any;
  console.log('tableProps', tableProps);
  console.log('queryResult', queryResult);
  return (
    <Show title="Document Detail">
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>User Name: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult?.member.name}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>User Email: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult?.member.email}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Status: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult?.status}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Project Id: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{queryResult?.projectId}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Created At: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{dayjs(queryResult?.createdAt).format('LLL')}</Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={4} style={{ textAlign: 'right' }}>
          <Space
            align="center"
            style={{
              marginBottom: '32px',
              marginRight: '4px',
            }}
          >
            <Text>Updated At: </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space
            style={{
              marginBottom: '32px',
            }}
          >
            <Text>{dayjs(queryResult?.updatedAt).format('LLL')}</Text>
          </Space>
        </Col>
      </Row>
    </Show>
  );
};
