import { InfoCircleOutlined } from '@ant-design/icons';
import { getValueProps } from '@pankod/refine-strapi-v4';
import { Form, Input } from 'antd';
import type { FormInstance } from 'antd/es/form';
import classNames from 'classnames';
import CollapseMenuDnd from 'views/content/containers/CollapseMenuDnd';
import UploadImage from 'views/content/containers/UploadImage';

interface Props {
  form: FormInstance<any>;
  isShow: boolean;
  isDraft?: boolean;
  diff: any;
}

export default function ImageAndDocumentsFormItems({
  form,
  isShow,
  diff,
}: Props) {
  return (
    <div
      className={classNames('hidden', {
        '!block': isShow,
      })}
    >
      <Form.Item
        name="previewImage"
        // label="Preview Image"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.previewImage !== undefined,
            })}
          >
            Preview Image
          </div>
        }
        valuePropName="fileList"
        getValueProps={(_data) => {
          let fileData = _data;

          if (
            fileData &&
            !fileData.file &&
            !fileData.fileList &&
            !Array.isArray(fileData)
          ) {
            fileData = [fileData];
          }
          return getValueProps(fileData, ``);
        }}
        tooltip={{
          icon: <InfoCircleOutlined />,
          title:
            'This image will be used as the preview image on the Investments page.',
        }}
        rules={[{ required: isShow }]}
      >
        <UploadImage multiple={false} />
      </Form.Item>
      <Form.Item
        name="projectImages"
        // label="Project Images"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.projectImages !== undefined,
            })}
          >
            Project Images
          </div>
        }
        valuePropName="fileList"
        getValueProps={(_data) => {
          let fileData = _data;

          if (
            fileData &&
            !fileData.file &&
            !fileData.fileList &&
            !Array.isArray(fileData)
          ) {
            fileData = [fileData];
          }
          const newValue = getValueProps(fileData, ``);

          return newValue;
          // return getValueProps(fileData, ``);
        }}
        rules={[{ required: isShow }]}
      >
        <UploadImage multiple={true} />
      </Form.Item>
      <Form.Item
        name="documents"
        // label="Documents"
        label={
          <div
            className={classNames({
              'bg-purple-100': diff?.documents !== undefined,
            })}
          >
            Documents
          </div>
        }
        rules={[{ required: isShow }]}
      >
        <Form.List name="documents">
          {(fields, { add, remove, move }) => {
            const items = fields.map(({ key, name, ...restField }, index) => {
              const title = form.getFieldValue(['documents', name, 'title']);

              return {
                id: key,
                index,
                name: title,
                content: (
                  <>
                    <Form.Item
                      {...restField}
                      name={[name, 'title']}
                      label="Title"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      valuePropName="fileList"
                      getValueProps={(_data) => {
                        let fileData = _data;

                        if (
                          fileData &&
                          !fileData.file &&
                          !fileData.fileList &&
                          !Array.isArray(fileData)
                        ) {
                          fileData = [fileData];
                        }
                        return getValueProps(fileData, ``);
                      }}
                      name={[name, 'file']}
                      label="file"
                    >
                      <UploadImage multiple={false} />
                    </Form.Item>
                  </>
                ),
              };
            });

            return (
              <>
                <CollapseMenuDnd items={items} remove={remove} move={move} />
                <div
                  className="w-full h-12 text-indigo-500 font-semibold cursor-pointer flex items-center justify-center hover:bg-slate-100 transition"
                  onClick={() => {
                    add({}, fields.length);
                  }}
                >
                  + Add an entry
                </div>
              </>
            );
          }}
        </Form.List>
      </Form.Item>
    </div>
  );
}
