import {
  DeleteButton,
  List,
  ShowButton,
  Space,
  Table,
  useTable,
} from '@pankod/refine-antd';
import type { HttpError, IResourceComponentsProps } from '@pankod/refine-core';
import type { Order } from '@tokenbricks/talfa-backend-typescript-axios-client';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

export const GroupList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<
    Order,
    HttpError,
    {
      orderCode: string;
      paymentType: string[];
      status: string[];
      createdAt: [Dayjs, Dayjs];
    }
  >();

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="name" title="Name" />

        <Table.Column
          dataIndex="createdAt"
          title="Created At"
          render={(value) => dayjs(value).format('LLL')}
        />
        <Table.Column
          dataIndex="updatedAt"
          title="Updated At"
          render={(value) => dayjs(value).format('LLL')}
        />
        <Table.Column
          title="Actions"
          dataIndex="id"
          render={(value) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={value} />
              <DeleteButton hideText size="small" recordItemId={value} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
