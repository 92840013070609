import '../style/styles.css';

import {
  AntdLayout,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from '@pankod/refine-antd';
import { useRegister, useRouterContext } from '@pankod/refine-core';
import { useParams } from '@pankod/refine-react-router-v6';
import React from 'react';

const { Title } = Typography;

export interface IRegisterForm {
  name: string;
}

export const Register: React.FC = () => {
  const { useLocation } = useRouterContext();
  const { roleId } = useParams();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get('token');

  const [form] = Form.useForm<IRegisterForm>();

  const { mutate: register } = useRegister();

  const CardTitle = (
    <Title level={3} className="title">
      Register as {roleId === '1' ? 'an admin' : 'a sponsor'}
    </Title>
  );

  return (
    <AntdLayout className="layout">
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div className="container">
            <div className="imageContainer">
              <img
                className="h-52"
                src="/images/logo/logo-white.svg"
                alt="talfa Logo"
              />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<IRegisterForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  if (token) {
                    register({ data: values, token });
                  }
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="name"
                  label="Username"
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="Username" />
                </Form.Item>
                <Button type="primary" size="large" htmlType="submit" block>
                  Register
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
