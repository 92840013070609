import { useQuery } from '@pankod/refine-core';
import { getProjectCMSData } from 'api/cms';
import type { ProjectDataAttributes } from 'api/cms/model/response';

interface Props {
  projectId: number;
  children: (project: ProjectDataAttributes | undefined) => React.ReactElement;
}
const ProjectCMSDataLoader = ({ projectId, children }: Props) => {
  const { data } = useQuery(['getProjectCMSData', projectId], async () => {
    const res = await getProjectCMSData(String(projectId));
    return res.data.data.attributes;
  });
  return children(data);
};

export default ProjectCMSDataLoader;
