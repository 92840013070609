import { Breadcrumb, PageHeader } from '@pankod/refine-antd';
import { useNavigation } from '@pankod/refine-core';
import { Button, Card, Form, Steps } from 'antd';
import { useEffect, useState } from 'react';
import { FormList } from 'views/projects/containers/FormList';
import { formStepsFields } from 'views/projects/data/form';
import { projectSteps } from 'views/projects/data/steps';
import { ProjectStep } from 'views/projects/enum/steps';
import { useProjectMethod } from 'views/projects/hooks/useProjectMethod';
import type { ProjectForm } from 'views/projects/model/project';

export const ProjectCreate: React.FC = () => {
  const { goBack } = useNavigation();
  const { createProjectMutation } = useProjectMethod();
  const [currentStep, setCurrentStep] = useState(ProjectStep.PROJECT_DETAILS);
  const [completedSteps, setCompletedSteps] = useState<ProjectStep[]>([]);
  const [form] = Form.useForm<ProjectForm>();
  const onChangeStep = (value: ProjectStep) => {
    if (completedSteps.includes(value)) {
      if (completedSteps.includes(currentStep)) {
        form.validateFields(formStepsFields[currentStep]).then(() => {
          setCurrentStep(value);
        });
      } else {
        setCurrentStep(value);
      }
    } else {
      form.validateFields(formStepsFields[currentStep]).then(() => {
        if (
          value === ProjectStep.SETTINGS &&
          completedSteps.includes(ProjectStep.IMAGES_AND_DOCUMENTS)
        ) {
          setCurrentStep(value);
        } else {
          setCurrentStep(currentStep + 1);
        }
        setCompletedSteps([...completedSteps, currentStep]);
      });
    }
  };

  const handleSaveAsDraft = () => {
    form.validateFields().then(() => {
      const value = form.getFieldsValue();
      createProjectMutation.mutate(value);
    });
  };

  const handleNext = () => {
    onChangeStep(currentStep + 1);
  };

  const handlePrevious = () => {
    onChangeStep(currentStep - 1);
  };

  useEffect(() => {
    const projectName = form.getFieldValue(['projectName']) as string;
    const tokenName = form.getFieldValue(['tokenName']) as string;

    if (currentStep === ProjectStep.SETTINGS && !tokenName) {
      const values: {
        urlHandle?: string;
        tokenName?: string;
        tokenSymbol?: string;
      } = {};
      if (!form.getFieldValue(['urlHandle'])) {
        values.urlHandle = projectName
          .toLocaleLowerCase()
          .replaceAll(' ', '-')
          .replaceAll('/', '');
      }
      if (!form.getFieldValue(['tokenName'])) {
        values.tokenName = projectName;
      }
      if (!form.getFieldValue(['tokenSymbol'])) {
        values.tokenSymbol = projectName
          .toLocaleUpperCase()
          .replaceAll(' ', '-');
      }
      form.setFieldsValue(values);
    }
  }, [currentStep]);

  return (
    <div>
      <PageHeader
        title="Create Project"
        breadcrumb={<Breadcrumb />}
        onBack={goBack}
      />
      <div className="mt-6 flex">
        <div className="w-80">
          <Steps
            direction="vertical"
            current={currentStep}
            onChange={onChangeStep}
            items={projectSteps}
          />
        </div>
        <div className="flex-1">
          <Card className="w-full px-12 py-5">
            <FormList
              form={form}
              step={currentStep}
              isDraft={true}
              initialValues={{
                investmentType: 'equity',
                propertyType: 'multifamily',
                investmentStrategy: 'core',

                distributionPeriod: 'Quarterly',
                // mock data
                // projectName: 'Project Name',
                // projectDescription: 'Project Description',
                // cityState: 'Garner, NC | Raleigh-Durham, NC',
                // addresses: [
                //   {
                //     address:
                //       '3452 Preston Trial, Garner, NC 43276 | Raleigh-Durham, NC',
                //   },
                // ],

                // initialDate: [
                //   dayjs().toString(),
                //   dayjs().add(1, 'month').toString(),
                // ],

                totalFundraising: 10000,
                pricePerToken: 1,

                minimumInvestment: 1,
                // minimumHoldingPeriod: 1,
                // sponsorInvestmentPeriod: '10 years',
                sponsorCoInvest$: 0,
                sponsorCoInvestPercentage: 0,

                targetedInvestorIRR: 0,
                targetedEquityMultiple: 1,
                totalProjectIRR: 0,
                targetedAverageCashYield: 0,
                // targetedDistributionStartDate: 'Q3 of 2024',
                // previewImage: [5],
                // projectImages: [5],
                // documents: [
                //   {
                //     title: 'info',
                //     file: [5],
                //   },
                // ],

                contents: [
                  {
                    title: 'Overview',
                    content:
                      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate, asperiores. Voluptatem eum officia aliquam vitae dignissimos ipsa a deleniti, tempora voluptatibus. Rem dolor non quasi eos veritatis ipsa, doloribus odit!',
                  },
                ],
              }}
            />
          </Card>
          <div className="mt-4 text-right">
            {/* <Button
              className="mr-4"
              onClick={() => {
                console.log(form.getFieldsValue());
              }}
            >
              Print Form Value (Dev)
            </Button> */}
            {currentStep !== ProjectStep.PROJECT_DETAILS && (
              <Button onClick={handlePrevious}>Previous</Button>
            )}
            {currentStep === ProjectStep.SETTINGS && (
              <Button
                className="ml-4"
                type="primary"
                onClick={handleSaveAsDraft}
                loading={createProjectMutation.isLoading}
              >
                Save as Draft
              </Button>
            )}
            {currentStep !== ProjectStep.SETTINGS && (
              <Button className="ml-4" onClick={handleNext}>
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
